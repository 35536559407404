import axios from "axios";
import { API_BASE_URL } from "config";
import { deleteAllCookies } from "./function/cookie";
import { showToast } from "./function/function";
import { getCookie, removeLocal, setLocal } from "./function/storage";
import { refreshAccessToken } from "./services/auth/services";

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// For Refreshing Token
let isAlreadyFetchingAccessToken = false;

// For Refreshing Token
let subscribers = [];

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  const token = getCookie("access_token");
  const refresh_token = getCookie("refresh_token");
  if (!token && refresh_token) {
    commonRefreshFn(config, refresh_token);
  }
  config.headers = {
    "Content-Type": "application/json",
  };
  if (token) config.headers.Authorization = `${token}`;
  return config;
});

// Add a response interceptor || Middleware for 401
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response,
    } = error;
    const originalRequest = config;
    if (response && response?.status === 401 && (window.location.pathname === "/auth/signin" ||
    window.location.pathname === "/auth/forgotpassword")) {
      showToast("Failed !", error.response.data.message, "error")
    } else if (response && response?.status === 401) {
      const refresh_token = getCookie("refresh_token");
      if (refresh_token) {
        return commonRefreshFn(originalRequest, refresh_token);
      }
    } else if (response && response?.status === 403) {
      showToast("Failed !", error.response.data.message, "error");
      deleteAllCookies();
    } else {
      return Promise.reject(error);
    }
  }
);

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const commonRefreshFn = (originalRequest, refresh_token) => {
  // Refresh Token Promise
  if (!isAlreadyFetchingAccessToken) {
    isAlreadyFetchingAccessToken = true;
    refreshAccessToken(refresh_token)
      .then((response) => {
        isAlreadyFetchingAccessToken = false;
        // Replacing Tokens
        removeLocal("access_token");
        removeLocal("refresh_token");
        setLocal("access_token", response.data.data.access_token);
        setLocal("refresh_token", response.data.data.refresh_token);
        return onAccessTokenFetched(response.data.data.access_token);
      })
      .catch((error) => {
        isAlreadyFetchingAccessToken = false;
        // Removing User State
        removeLocal("access_token");
        removeLocal("refresh_token");
        if (
          window.location.pathname === "/auth/signin" ||
          window.location.pathname === "/auth/forgotpassword"
        ) {
          return;
        } else {
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        }
      });
  }
  const retryOriginalRequest = new Promise((resolve) => addSubscriber((accessToken) => {
    // Make sure to assign accessToken according to your response.
    // Change Authorization header
    originalRequest.headers["Authorization"] = `${accessToken}`;
    return axios(originalRequest).then((res) => resolve(res));
  })
  );
  return retryOriginalRequest.then((res) => res);
};
