import { axiosInstance } from "common/axiosConfig";
import { showToast } from "common/function/function";

export const postCast = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`casts`, value)
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const postCastChannel = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`casts/channel`, { cast_link: value })
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const postCastGuests = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`cast-guests`, value)
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const patchCastGuestsLeave = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`cast-guests/${value}`)
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        // error.response && error.response.data.message
        //   ? showToast("Failed !", error.response.data.message, "error")
        //   : reject({ message: `Something Went Wrong` });
      })
  );

export const patchCastDestroye = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`casts/${value?.id}`, { last_active: value?.last_active })
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const getCastGuestList = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`cast-guests/${value?.id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ message: `Something Went Wrong` });
      })
  );
