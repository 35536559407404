import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pubnub from "pubnub";
import {
  handleCastGuests,
  handleCastGuestsLeave,
  handleCastGuestList,
} from "redux/action/cast";
import { createCookie, getCookie } from "common/function/cookie";
import { getLocal, setLocal } from "common/function/storage";
import {
  REACT_APP_PUBLISH_KEY,
  REACT_APP_SUBSCRIBE_KEY,
  REACT_APP_SECRETE_KEY,
} from "config";

const usePubNubStore = () => {
  const dispatch = useDispatch();
  // const _AUTH_STORE = useSelector((e) => e?.memorize?.authStore);
  const _LEAVE_EVENT_FIRE = useSelector((e) => e.common.leaveEvent);

  const _CAST_GUEST_LIST = useSelector((e) => e.common.getCastGuestLists);

  let parseDetails = getCookie("login_user")
  ? JSON.parse(getCookie("login_user"))
  : null;
const _AUTH_STORE = parseDetails;

  useEffect(() => {
    let cast_user_log_id = _CAST_GUEST_LIST?.data?.find(
      (item) => item?.presence_id === _LEAVE_EVENT_FIRE?.uuid
    )?.cast_user_log_id;
    if (cast_user_log_id === undefined) return;
    if (_LEAVE_EVENT_FIRE && cast_user_log_id) dispatch(handleCastGuestsLeave(cast_user_log_id));
  }, [_LEAVE_EVENT_FIRE]);

  let successSubscribe = false;

  let getUUIDs = getCookie("uuids");

  if (getUUIDs === "") {
    let generatedUUID = Pubnub.generateUUID();
    createCookie("uuids", generatedUUID, 2);
  }
  const pubnub = new Pubnub({
    publishKey: REACT_APP_PUBLISH_KEY,
    subscribeKey: REACT_APP_SUBSCRIBE_KEY,
    secretKey: REACT_APP_SECRETE_KEY,
    userId: getUUIDs,
    keepAlive: true,
    keepAliveSettings: {
      ketKeepAliveTimeout: 86400000,
      timeout: 86400000,
    },
  });

  const guestJoin = async (uuid, channel) => {
    const cast_channel = getCookie("cast_channel")
      ? JSON.parse(getCookie("cast_channel"))
      : JSON.parse(getLocal("cast_channel"));

    const _JN = getLocal("joinName");
    if (successSubscribe) {
      let cast_id =
        cast_channel?.length &&
        cast_channel?.find((item) => item?.cast_channel === channel)?.id;
      let params_cast = {
        id: cast_id,
      };
      const params = {
        cast_id: cast_id,
        presence_id: uuid,
        user_name: _JN ? _JN : _AUTH_STORE?.fullname,
      };
      successSubscribe = false;
      if (params && cast_id) {
        await dispatch(handleCastGuests(params));
      }
      if (params_cast?.id) {
        await dispatch(handleCastGuestList(params_cast));
      }
    }
  };

  pubnub.addListener({
    status: function (statusEvent) {
      // if (statusEvent.category === "PNConnectedCategory") {
      //   console.log("Connected to PubNub!");
      //   //Access the parameters provided in the URL
      //   console.log("statusEvent", statusEvent);
      // }
    },
    message: async function (receivedMessage) {
      // handle message
      if (receivedMessage) {
        let d = getCookie("guest")
          ? JSON.parse(getCookie("guest"))
          : null;
        if (
          receivedMessage?.userMetadata?.uuid === d?.presence_id &&
          receivedMessage?.userMetadata?.uuid !== ""
        ) {
          dispatch({
            type: "HISTORY_CAST_EVENT",
            payload: receivedMessage.message,
          });
        } else if (receivedMessage?.message?.event === "hostStop") {
          dispatch({
            type: "HISTORY_CAST_EVENT",
            payload: receivedMessage.message,
          });
          dispatch({
            type: "HOST_STOP_CAST",
            payload: true,
          });
          dispatch({ type: "NOW_PLAYING_LIST", payload: [] });
        } else if (receivedMessage?.message?.event === "hostStart") {
          dispatch({
            type: "HOST_STOP_CAST",
            payload: false,
          });
        } else {
          dispatch({
            type: "HISTORY_CAST_EVENT",
            payload: receivedMessage.message,
          });
        }
      }
    },
    presence: async function (presenceEvent) {
      /*      const cast_channel = JSON.parse(getLocal("cast_channel")); */
      let uuidMetaData = await pubnub.objects.getUUIDMetadata();
      if (presenceEvent.action === "join") {

        if (!uuidMetaData?.data?.custom?.isHost) {
          dispatch({
            type: "GUEST_JOINED_EVENT",
            payload: presenceEvent?.uuid,
          });
        }
        dispatch({ type: "PRESENCE_ID", payload: presenceEvent?.uuid });
        if (uuidMetaData?.data?.id === presenceEvent?.uuid) {
          setLocal("presence_event", JSON.stringify(presenceEvent));
          await guestJoin(presenceEvent?.uuid, presenceEvent?.channel);
        }
      } else if (presenceEvent?.action === "leave") {
        dispatch({ type: "LEAVE_EVENT", payload: presenceEvent });
      }
    },
  });

  pubnub.subscribeMessage = (params) => {
    successSubscribe = true;
    pubnub.subscribe({
      channels: [params],
      withPresence: true,
    });
  };

  pubnub.publishMessage = (params) =>
    pubnub.publish(
      {
        channel: params?.cast_channel,
        message: params,
        storeInHistory: params?.storeInHistory,
        meta: {
          uuid: params?.presence_id ? params?.presence_id : "",
        },
      },
      function (status, response) {
        if (response) {
          // console.log("status", status, "response", response);
        }
      }
    );
  return pubnub;
};

export default usePubNubStore;
