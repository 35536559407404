import { axiosInstance } from "common/axiosConfig";
import { showToast } from "common/function/function";
import { errorLog } from "common/utility/utils";

/* Adds an existing playlist to a section as a sectionPlaylist  */
export const postAddExistingPlaylist = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`section-playlist/add-existing-playlist`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        console.error(error);
        if (error.response && error.response.data.message) {
          showToast("Failed !", error.response.data.message, "error");
        }
        reject({ message: `Something Went Wrong` });
      })
  );

/* Shuffles section playlists inside a section  */
export const postSectionPlaylistUpdateOrder = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`section-playlist/update-order`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        console.error(error);
        if (error.response && error.response.data.message) {
          showToast("Failed !", error.response.data.message, "error");
        }
        reject({ message: `Something Went Wrong` });
      })
  );
