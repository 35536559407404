import * as Actions from "redux/action/section/index";

const initialState = {
  sidebar: "playlist",
  mainSidebar: false,
  createPlaylist: false,
  sidebarVolume: 50,
  sidebarVolumeMuted: false,
  sectionID: "",
  sidebarTrackVolume: 50,
  addToSection: false,
  updateToSection: false,
  deleteToSection: false,
  playList_Id: "",
  playList_Name: "",
  addToPlayList: false,
  playListTrackId: "",
  addTrackList: false,
  addTrackListId: "",
  editSectionName: "",
  addTrackToPlaylistTrackId: [],
  addToTrackMulti: false,
  addToSectionMulti: {
    flag: false,
    playListId: "",
  },
  addTracksAfterPlaylistIsCreated: [],
  addMultipleTrackList: false,
  createPlaylistSection: false,
  FileUploadModal: false,
  isMinimizedFileUploadModal: false,
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case Actions.POST_TRACKTOPLAYLIST:
    case Actions.POST_SECTION_PLAYLIST: {
      return { ...state, createPlaylist: false };
    }
    case "SIDEBAR": {
      return { ...state, sidebar: action.payload };
    }
    case "MAIN_SIDEBAR": {
      return { ...state, mainSidebar: action.payload };
    }
    case "CREATE_PLAYLIST": {
      return {
        ...state,
        createPlaylist: action.payload,
        addTracksAfterPlaylistIsCreated: [],
        initialSectionSelectId: null,
        volume: 50,
      };
    }
    case "CREATE_PLAYLIST_WITH_TRACKS": {
      const {
        addTracksAfterPlaylistIsCreated,
        createPlaylist,
        initialSectionSelectId,
        volume,
      } = action.payload;
      return {
        createPlaylist: createPlaylist,
        addTracksAfterPlaylistIsCreated,
        initialSectionSelectId,
        volume: volume,
      };
    }
    case "CREATE_PLAYLIST_SECTION": {
      return {
        ...state,
        createPlaylistSection: action.payload,
        addTracksAfterPlaylistIsCreated: [],
        initialSectionSelectId: null,
        volume: 50,
      };
    }
    case "SIDEBAR_VOLUME": {
      const isNumber = isFinite(+action.payload);
      return { ...state, sidebarVolume: isNumber ? action.payload : 50 };
    }
    case "SIDEBAR_VOLUME_MUTED": {
      return { ...state, sidebarVolumeMuted: action.payload };
    }
    case "ADD_TO_SECTION": {
      return { ...state, addToSection: action.payload };
    }
    case "UPDATE_TO_SECTION": {
      return { ...state, updateToSection: action.payload };
    }
    case "PLAYLIST_NAME": {
      return { ...state, playList_Name: action.payload };
    }
    case "DELETE_TO_SECTION": {
      return { ...state, deleteToSection: action.payload };
    }
    case "ADD_TO_PLAYLIST_TRACK_ID": {
      return { ...state, playListTrackId: action.payload };
    }
    case "ADD_TRACKLIST": {
      return { ...state, addTrackList: action.payload };
    }
    case "ADD_MULTIPLE_TRACKS": {
      return { ...state, addMultipleTrackList: action.payload };
    }
    case "ADD_TRACKLIST_ID": {
      return { ...state, addTrackListId: action.payload };
    }
    case "ADD_TRACK_TO_PLAYLIST_TRACK_ID": {
      let data = [...state?.addTrackToPlaylistTrackId];
      data.push(action?.payload);
      return {
        ...state,
        addTrackToPlaylistTrackId: data,
      };
    }
    case "REMOVE_TRACK_TO_PLAYLIST_TRACK_ID": {
      let data = state?.addTrackToPlaylistTrackId?.findIndex(
        (item) => item === action?.payload
      );
      state?.addTrackToPlaylistTrackId?.splice(data, 1);

      return {
        ...state,
        addTrackToPlaylistTrackId:
          action?.payload?.length === 0
            ? action?.payload
            : state?.addTrackToPlaylistTrackId,
      };
    }

    case "ADD_TO_TRACK_MULTI": {
      return { ...state, addToTrackMulti: action.payload };
    }

    case "ADD_TO_SECTION_MULTI": {
      return { ...state, addToSectionMulti: action.payload };
    }

    case "PLAYLIST_ID": {
      return { ...state, playList_Id: action.payload };
    }
    case "SECTION_ID": {
      return { ...state, sectionID: action.payload };
    }
    case "OPEN_FILE_UPLOAD_MODAL": {
      return { ...state, FileUploadModal: action.payload };
    }
    case "EDIT_SECTION_NAME": {
      return { ...state, editSectionName: action.payload };
    }
    case "MINIMIZED_FILE_UPLOAD_MODAL": {
      return { ...state, isMinimizedFileUploadModal: action.payload };
    }
    default: {
      return state;
    }
  }
}
