import { createCookie } from "common/function/cookie";
import { setLocal } from "common/function/storage";
import {
  postCast,
  postCastChannel,
  postCastGuests,
  patchCastDestroye,
  patchCastGuestsLeave,
  getCastGuestList,
} from "common/services/cast/services";

export const POST_CAST_LOAD = "[CAST] POST_SECTION_LOAD";
export const POST_CAST_ERROR = "[CAST] POST_SECTION_ERROR";
export const POST_CAST = "[CAST] POST_CAST";
export const POST_CAST_CHANNEL = "[CAST] POST_CAST_CHANNEL";
export const PATCH_CAST_DESTROYED = "[CAST] PATCH_CAST_DESTROYED";
export const POST_CAST_GUESTS = "[CAST] POST_CAST_GUESTS";
export const PATCH_CAST_GUESTS_LEAVE = "[CAST] PATCH_CAST_GUESTS_LEAVE";
export const GET_CAST_GUESTS_LIST = "[CAST] GET_CAST_GUESTS_LIST";

export function handleCast(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      const response = await postCast(values);
      let res = [];
      res.push(response);
      await setLocal("cast_channel", JSON.stringify(res));
      dispatch({
        type: POST_CAST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleCastChannel(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      const response = await postCastChannel(values);
      dispatch({
        type: POST_CAST_CHANNEL,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleCastGuests(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      let response;
      if(values){
         response = await postCastGuests(values);
      } 
      dispatch({
        type: POST_CAST_GUESTS,
        payload: response,
      });
      createCookie("guest", JSON.stringify(response), 2);
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleCastGuestsLeave(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      const response = await patchCastGuestsLeave(values);
      dispatch({
        type: PATCH_CAST_GUESTS_LEAVE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        // payload: { errorMessage: error.message },
      });
    }
  };
}

export function handlePatchDistroy(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      const response = await patchCastDestroye(values);
      dispatch({
        type: PATCH_CAST_DESTROYED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleCastGuestList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_CAST_LOAD,
        payload: true,
      });
      const response = await getCastGuestList(values);
      if(response){
        dispatch({
          type: GET_CAST_GUESTS_LIST,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: POST_CAST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
