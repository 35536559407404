import * as Actions from "redux/action/auth/index";
import { setLocal, setCookie } from "common/function/storage";
const initialState = {
  //   entities: [],
  loading: false,
  status: false,
  error: false,
  message: "",
};

const Auth = function (state = initialState, action) {
  switch (action.type) {
    case Actions.POST_AUTH_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case Actions.POST_LOGIN: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        public_id:action?.payload?.public_id
      };
      setLocal("login_user", JSON.stringify(userDetails));
      setCookie("login_user", JSON.stringify(userDetails));
      setLocal("access_token", action?.payload?.access_token);
      setLocal("refresh_token", action?.payload?.refresh_token);

      return {
        ...state,
        loading: false,
        error: false,
        message: "",
      };
    }

    case Actions.GET_AUTH_VERITY:
    case Actions.POST_SIGNUP:
    case Actions.POST_RESET_PASSWORD:
    case Actions.GET_FORGOT_PASSWORD:
    case Actions.GET_SEND_MAIL: {
      return {
        ...state,
        loading: false,
        error: false,
        message: "",
      };
    }

    case Actions.GET_LOGOUT: {
      return {
        ...state,
        loading: false,
        error: false,
        message: "",
      };
    }

    case Actions.POST_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        status: false,
        message: action.payload.errorMessage,
        error: true,
        insertError: true,
      };
    }
    default: {
      return state;
    }
  }
};
export default Auth;
