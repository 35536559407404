import amplitude from "amplitude-js";
import { REACT_APP_AMPLITUDE_KEY, REACT_NODE_ENV } from "config";

const useAnalyticsStore = () => {
  const analytics = {};
  amplitude.getInstance().init(REACT_APP_AMPLITUDE_KEY, null, {
    // optional configuration options
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true,
  });

  analytics.logEvent = (eventName, eventData) => {
    if(REACT_NODE_ENV === "production") {
      amplitude.getInstance().logEvent(eventName);
    } else {
      console.log("eventName", eventName);
      // console.log("eventData", eventData);
    }
  };

  return analytics;
};

export default useAnalyticsStore;
