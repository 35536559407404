/* eslint-disable array-callback-return */
import * as Actions from "redux/action/section/index";
import * as Casts from "redux/action/cast/index";
import * as playTimeUrl from "redux/action/playTimeUrl";
import * as SectionPlaylistActions from "redux/action/section-playlist/index";
import * as immutable from "common/function/immutable-helpers";
import { setLocal, getLocal } from "common/function/storage";
import { onlyUnique, getMobileOperatingSystem} from "common/function/function";
const initialState = {
  logoutSuccess: false,
  sectionList: [],
  playList: [],
  playListSearched: [],
  lhmPlayTrackId: "",
  loading: true,
  sectionPlayList: [],
  shuffleTrackId: {},
  shuffleTrackWS: {},
  collapse: [],
  loopTrack: {},
  expandedTrackIds: "",
  loopIds: "",
  volumeWave: "",
  playListPlay: false,
  focusSectionPlaylistId: null,
  peak: [],
  sec_id: "",
  playTrackID: "",
  playlist_edit_id: "",
  castChannelGuest: {},
  sidebarTracks: false,
  addSectionPlaylist: {},
  postTrackList: {},
  postPlaylist: {},
  sectioLoad: {},
  playlistPlaceholderDummies: [],
  searchString: false,
  searchStringValue: "",
  track_id_name: [],
  section_sequence: "",
  postTrackToPlaylistError: null,
  //Cast Module
  castChannel: {},
  cast: {},
  presence_id: "",
  getCastGuestLists: [],
  memberListPopUp: false,
  castVolume: 50,
  hostStopCast: false,
  castOnOff: false,
  memberStore: "",
  leaveEvent: "",
  castMobileHistory: false,
  nowPlayingModal: false,
  nowPlayingList: [],
  finalNowPlayingList: [],
  lhmNowPlayingList: [],
  castPusblishEvent: undefined,
  wavesurfer: {},
  trackList: [],
  trackListController: [],
  memberListPopUpShow: false,
  multiSelect: "",
  quickFadeCount: "",
  expandedMEnuIds: "",
  commonValue: "",
  DesktopCommonValue: "",
  dropTrackToPlaylist: false,
  playingFade: {},
  currentFadeInVolume: "",
  finalChangeVolume: false,
  parentFileUpload: {},
  currentFileData: [],
  fileUploadError: false,
  removeAllFile: [],
  uploadFileProgress: "",
  uploadTracksHeaderData: [],
  guestJoinedEvent: "",
  getStorageData: {},
  headerProgress: "",
  uploadFileLength: [],
  fileUploadAllError: false,
  minimizePinnedFlag:false,
  pinnedFlag:[],
  loaderTrack:false,
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case "GET_LOGOUT": {
      return {
        ...state,
        logoutSuccess: action.payload,
      };
    }

    case Actions.GET_SECTION: {
      action?.payload?.data?.forEach((element) => {
        element.playing = false;
      });
      return {
        ...state,
        sectionList: action?.payload?.data
          ? action?.payload?.data
          : action?.payload,
        loader: false,
      };
    }

    case Actions.EDIT_SECTION: {
      let sectionPlayListAll = [];
      let sectionList = [];
      if (action?.payload?.data === undefined) {
        sectionPlayListAll = state?.sectionPlayListAll.map((item) => {
          if (
            item?.id ===
            (action?.payload[0]?.playlist_id || action?.payload[0]?.id)
          ) {
            return {
              ...item,
              section: action?.payload[0]?.section,
            };
          }
          return item;
        });
        sectionList = state?.sectionList?.map((item) => {
          if (item?.id === action?.payload[0]?.id) {
            item.section = action?.payload[0]?.section;
          }
          return item;
        });
      } else {
        let [updatedSection] = action?.payload?.data;
        if (state.sectionList.length) {
          sectionList = state.sectionList.map((section) => {
            if (section.id === updatedSection.id) {
              return { ...section, ...updatedSection };
            }
            return section;
          });
        } else {
          updatedSection.playing = false;
          state.sectionList = [updatedSection];
        }

        sectionPlayListAll = state?.sectionPlayListAll.map((item) => {
          item?.playlists?.map((pl) => {
            if (
              pl?.playlist_id ===
              (action?.payload?.data[0]?.playlist_id ||
                action?.payload?.data[0]?.id)
            ) {
              return {
                ...item,
                name: action?.payload?.data[0]?.section,
              };
            }
            return item;
          });
        });

        sectionPlayListAll = state?.sectionPlayListAll.map((item) => {
          if (
            item?.id ===
            (action?.payload?.data[0]?.playlist_id ||
              action?.payload?.data[0]?.id)
          ) {
            return {
              ...item,
              section: action?.payload?.data[0]?.section,
            };
          }
          return item;
        });
      }

      return {
        ...state,
        sectionPlayListAll,
        sectionList,
        loader: false,
      };
    }

    case Actions.PUT_SECTION_SEQUENCE: {
      const sectionPlayListAll = state?.sectionPlayListAll?.map((item) => {
        const sequenceSection = action?.payload?.data?.map((data) => {
          if (item?.id === data?.id) {
            item.section_sequence = data.section_sequence;
          }
          return data;
        });
        return {
          ...item,
          sequenceSection,
        };
      });
      sectionPlayListAll.sort(
        (a, b) => a.section_sequence - b.section_sequence
      );

      return {
        ...state,
        sectionList: action?.payload?.data
          ? action?.payload?.data
          : action?.payload,
        sectionPlayListAll,
        loader: false,
      };
    }
    case Actions.POST_PLAYLIST_LOAD:
    case Actions.POST_SECTION_LOAD: {
      return {
        ...state,
        // loader: true,
        loaderTrack: true,
      };
    }
    case Actions.GET_SECTION_PLAYLIST_BY_ID: {
      return {
        ...state,
        sectionPlayList: action?.payload,
        loader: false,
      };
    }

    //playing condition
    case Actions.GET_SECTION_PLAYLIST_ALL: {
      let sectionPlayListAll = action?.payload.map((item) => {
        item?.playlists?.map((pl) => {
          if(getMobileOperatingSystem() === "iOS"){
            pl.toggled = false
          }
          if (pl.pinned) {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = true));
          } else {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = false));
          }
          return pl;
        });
        return item;
      });

      return {
        ...state,
        sectionPlayListAll: sectionPlayListAll,
        loader: false,
      };
    }

    case Actions.PINNING_PLAYLIST_LIST: {
      state.sectionPlayListAll.forEach((item) => {
        item?.playlists?.forEach((pl) => {
          if (pl?.playlist_id === action?.payload[0]?.id) {
              pl.pinned = action?.payload[0]?.pinned;
          }else if(pl.pinned){
              pl.pinned = false;
          }
          return pl;
        });
        return item;
      });
      return {
        ...state,
        // sectionPlayListAll: sectionPlayListAll,
        pinnedFlag:action?.payload[0],
        loader: false,
      };
    }

    case Actions.GET_PLAYLIST: {
      return {
        ...state,
        playList: action?.payload,
        loader: false,
      };
    }
    case Actions.GET_PLAYLIST_SEARCH: {

      action?.payload?.data?.map((ele) => {
        state?.lhmNowPlayingList?.map((item) => {
          if (item.track?.playlist_id === ele?.id) {
            ele?.tracks?.map((element) => {
              if (
                item.track.id === element?.id &&
                item?.track?.trackOrigin === "sidebar"
              ) {
                element.pinned = true;
              }
              return element;
            });
          }
          return item;
        });
        return ele;
      });

      return {
        ...state,
        playListSearched: action?.payload,
        loader: false,
      };
    }

    case Actions.GET_TRACKLIST: {
      return {
        ...state,
        trackList: action?.payload,
        loaderTrack: false,
      };
    }

    case Actions.GET_TRACKLIST_CONTROLLER: {
      return {
        ...state,
        trackListController: action?.payload,
        loader: false,
        loaderTrack: false,
      };
    }

    case Actions.GET_PLAYLIST_SIDEBAR: {
      return {
        ...state,
        sidebarTracks: action?.payload,
      };
    }

    case "LHM_PLAY_TRACK_ID": {
      return {
        ...state,
        lhmPlayTrackId: action?.payload,
      };
    }

    case Actions.COLLASPE_PLAYLIST_LIST: {
      return {
        ...state,
        collapse: action?.payload,
        loader: false,
      };
    }

    case Actions.TOGGLE_PLAY_FEATURE: {
      return {
        ...state,
        toggled: action?.payload,
        loader: false,
      };
    }

    case Actions.LOOP_TRACK: {
      return {
        ...state,
        loopTrack: action?.payload,
        loader: false,
      };
    }

    case Actions.UPDATE_PLAYLIST_TRACK: {
      const sectionPlayListAll = state.sectionPlayListAll.map(
        (sectionPlaylist) => {
          let didUpdate = false;
          const newPlaylists = sectionPlaylist.playlists?.map((pl) => {
            if (pl.playlist_id === action.payload.playlist_id) {
              const indexOfOldTrack = pl.tracks.findIndex(
                (track) => track.id === action.payload.id
              );
              if (indexOfOldTrack === -1) return pl;
              didUpdate = true;
              return {
                ...pl,
                tracks: [
                  ...pl.tracks.slice(0, indexOfOldTrack),
                  action.payload,
                  ...pl.tracks.slice(indexOfOldTrack + 1),
                ],
              };
            }
            return pl;
          });

          if (didUpdate) {
            return {
              ...sectionPlaylist,
              playlists: newPlaylists,
            };
          } else {
            return sectionPlaylist;
          }
        }
      );

      return {
        ...state,
        sectionPlayListAll,
      };
    }

    case Actions.POST_SECTION_PLAYLIST: {
      const sectionPlayListAll = state?.sectionPlayListAll?.map((sp) => {
        if (sp?.id === action?.payload?.section_id) {
          return {
            ...sp,
            playlists: [...sp.playlists, action?.payload],
          };
        } else {
          return sp;
        }
      });

      return {
        ...state,
        sectionPlayListAll,
        addSectionPlaylist: action?.payload,
        loader: false,
      };
    }

    case Actions.PATCH_TRACK_SHUFFLE: {
      const sections = action?.payload;

      // create a list of playlists
      const newPlaylists = new Map();
      for (const section of sections) {
        for (const playlist of section.playlists) {
          if (newPlaylists.has(playlist.playlist_id) === false) {
            const {
              collapsed,
              playlist_id,
              id,
              section_id,
              ...playlistAttributes
            } = playlist;
            const newPlaylist = { id: playlist_id, ...playlistAttributes };
            newPlaylists.set(playlist.playlist_id, newPlaylist);
          }
        }
      }
      const newPlaylistData = state.playList.data.map((playlist) =>
        newPlaylists.has(playlist.id) ? newPlaylists.get(playlist.id) : playlist
      );

      let sectionPlayListAll = action?.payload.map((item) => {
        item?.playlists?.map((pl) => {
          if (pl.pinned) {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = true));
          } else {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = false));
          }
          return pl;
        });
        return item;
      });


      return {
        ...state,
        sectionPlayListAll: sectionPlayListAll,
        playList: {
          ...state.playList,
          data: newPlaylistData,
        },
        loader: false,
      };
    }

    case SectionPlaylistActions.ADD_EXISTING_PLAYLIST_TO_SECTION_PENDING:
    case SectionPlaylistActions.UPDATE_SECTION_PLAYLIST_ORDER_PENDING: {
      return {
        ...state,
        loader: true,
        focusSectionPlaylistId: null,
      };
    }

    case SectionPlaylistActions.ADD_EXISTING_PLAYLIST_TO_SECTION_FULFILLED: {
      if (action?.payload.pinned) {
        action?.payload?.tracks?.map((ele) => (ele.pinned_playlist = true));
        } else {
          action?.payload?.tracks?.map((ele) => (ele.pinned_playlist = false));
        }
      const newSectionPlaylist = action.payload;
      const index = newSectionPlaylist.list_order - 1;

      const sectionPlayListAll = state.sectionPlayListAll.map((section) => {
        if (section.id === newSectionPlaylist.section_id) {
          const playlists = [...section.playlists];
          playlists.splice(index, 0, newSectionPlaylist);
          return {
            ...section,
            playlists,
          };
        } else {
          return section;
        }
      });

      return {
        ...state,
        sectionPlayListAll,
        loader: false,
        focusSectionPlaylistId: newSectionPlaylist.id,
      };
    }

    case SectionPlaylistActions.ADD_EXISTING_PLAYLIST_TO_SECTION_REJECTED: {
      // synchronize previous state with drag and drop system (it is still showing the preview which should be removed after this request fails)
      const sectionPlayListAll = [...state.sectionPlayListAll];

      return {
        ...state,
        sectionPlayListAll,
        loader: false,
      };
    }

    case SectionPlaylistActions.UPDATE_SECTION_PLAYLIST_ORDER_FULFILLED: {
      const playlistOrderPatch = action.payload.playlist;
      const sectionIds = playlistOrderPatch
        .map((x) => x.section_id)
        .filter(onlyUnique);
      const listOrderMap = new Map();
      for (const playlist of playlistOrderPatch) {
        listOrderMap.set(playlist.id, playlist.list_order);
      }

      const sectionPlayListAll = state.sectionPlayListAll.map((section) => {
        if (sectionIds.includes(section.id)) {
          const playlists = [...section.playlists].map((sp) => {
            if (listOrderMap.has(sp.id)) {
              return {
                ...sp,
                list_order: listOrderMap.get(sp.id),
              };
            } else {
              return sp;
            }
          });
          playlists.sort((a, b) => a.list_order - b.list_order);

          return {
            ...section,
            playlists,
          };
        } else {
          return section;
        }
      });

      return {
        ...state,
        sectionPlayListAll,
        loader: false,
        focusSectionPlaylistId: action.payload.sectionPlaylistId,
      };
    }

    case SectionPlaylistActions.UPDATE_SECTION_PLAYLIST_ORDER_REJECTED: {
      // synchronize previous state with drag and drop system (it is still showing the preview which should be removed after this request fails)
      const sectionPlayListAll = [...state.sectionPlayListAll];

      return {
        ...state,
        sectionPlayListAll,
        loader: false,
      };
    }

    case Actions.DELETE_PLAYLIST_TRACK_BY_ID_PENDING: {
      const { id } = action?.payload;

      const { sectionPlayListAll, playList } =
        immutable.deleteTrackFromSectionPlaylists(
          state,
          (track) => track.id === id
        );

      return {
        ...state,
        sectionPlayListAll,
        playList,
      };
    }

    case Actions.DELETE_SECTION_LIST:
    case Actions.DELETE_PLAYLIST_LIST:
    case Actions.BUTTON_STATE_TRACK:
    case Actions.VOLUME_TRACK:
    case Actions.POST_ACCOUNT_SETTING:
    case Actions.POST_CHANGE_PASSWORD: {
      return {
        ...state,
        loader: false,
      };
    }

    case Actions.POST_SECTION: {
      const sectionPlayListAll = state?.sectionPlayListAll.map((item) => {
        if (action?.payload.pinned) {
          action?.payload?.tracks?.map((ele) => (ele.pinned_playlist = true));
          } else {
            action?.payload?.tracks?.map((ele) => (ele.pinned_playlist = false));
          }

        if (item?.id === action?.payload?.section_id) {
          return {
            ...item,
            playlists: [...item.playlists, action?.payload],
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        sectionPlayListAll,
        loader: false,
      };
    }

    case Actions.POST_TRACKTOPLAYLIST_ERROR: {
      return {
        ...state,
        postTrackToPlaylistError: action?.payload,
        loader: false,
      };
    }

    case Actions.POST_TRACKTOPLAYLIST: {
      const sectionPlayListAll = state.sectionPlayListAll.map((item) => {
        const playlists = item?.playlists?.map((pl) => {
          if (pl?.playlist_id === action?.payload?.playlist_id) {
            const playOrder = action?.payload?.play_order;
            const index = playOrder - 1;
            // insert the new track (at the correct index)

            const tracks = [
              ...pl.tracks.slice(0, index),
              action?.payload,
              ...pl.tracks.slice(index),
            ].map((t) => {
              // increment play_order of tracks with a higher play_order than the one we just inserted
              if (t.play_order > playOrder) {
                return {
                  ...t,
                  play_order: t.play_order + 1,
                };
              } else return t;
            });
            return {
              ...pl,
              tracks,
            };
          }
          return pl;
        });

        return {
          ...item,
          playlists,
        };
      });
      
      sectionPlayListAll.map((item) => {
        item?.playlists?.map((pl) => {
          if (pl.pinned) {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = true));
          } else {
            pl?.tracks?.map((ele) => (ele.pinned_playlist = false));
          }
          return pl;
        });
        return item;
      });

      return {
        ...state,
        // postTrackList: action?.payload,
        sectionPlayListAll,
        loader: false,
      };
    }

    case Actions.PATCH_EDIT_PLAY_LIST: {
      const sectionPlayListAll = state?.sectionPlayListAll?.map((item) => {
        const playlists = item?.playlists?.map((pl) => {
          if (
            pl?.playlist_id ===
            (action?.payload[0]?.playlist_id || action?.payload[0]?.id)
          ) {
            return {
              ...pl,
              name: action?.payload[0]?.name,
            };
          }
          return pl;
        });
        return {
          ...item,
          playlists,
        };
      });

      return {
        ...state,
        sectionPlayListAll,
        loader: false,
      };
    }

    //
    case "SECTION_SEQUENCE": {
      return {
        ...state,
        section_sequence: action?.payload,
      };
    }

    case "SHUFFLE_TRACK_ID": {
      return {
        ...state,
        shuffleTrackId: action?.payload,
      };
    }

    case "SHUFFLE_TRACK_WS": {
      return {
        ...state,
        shuffleTrackWS: action?.payload,
      };
    }

    case "SECTION_LOAD": {
      return {
        ...state,
        sectioLoad: action?.payload,
      };
    }

    case "TRACK_ID_WAVE": {
      return {
        ...state,
        expandedTrackIds: action?.payload,
      };
    }
    case "TRACK_ID_MENU": {
      return {
        ...state,
        expandedMEnuIds: action?.payload,
      };
    }
    case "WAVESURFER": {
      return {
        ...state,
        wavesurfer: action?.payload,
      };
    }

    case "MOVE_PLAYER": {
      const from = action.payload.from;
      const to = action.payload.to;
      if (from === state.expandedTrackIds) {
        return {
          ...state,
          expandedTrackIds: to,
        };
      } else {
        return state;
      }
    }

    //
    case "LOOP_ID_WAVE": {
      return {
        ...state,
        loopIds: action?.payload,
      };
    }
    //Multiselct icon true and false
    case "MULTI_SELECT": {
      return {
        ...state,
        multiSelect: action?.payload,
      };
    }
    //search string collaps true and false
    case "SEARCH_STRING": {
      return {
        ...state,
        searchString: action?.payload,
      };
    }
    //quick fade count
    case "QUICKFADE_COUNT": {
      return {
        ...state,
        quickFadeCount: action?.payload,
      };
    }

    case "SEARCH_STRING_VALUE": {
      return {
        ...state,
        searchStringValue: action?.payload,
      };
    }
    //Track id and name for Tracks add to playlist

    case "TRACK_ID_NAME": {
      return {
        ...state,
        track_id_name: action?.payload,
      };
    }
    case "VOLUME_WAVE": {
      return {
        ...state,
        volumeWave: action?.payload,
      };
    }

    case "PLAYLIST_PLAY": {
      return { ...state, playListPlay: action?.payload };
    }

    case "PEAK": {
      return {
        ...state,
        peak: action?.payload,
      };
    }

    case "UNI_SEC_ID": {
      return {
        ...state,
        sec_id: action?.payload,
      };
    }

    case "OPEN_PLAYLIST_ID": {
      return {
        ...state,
        playlist_edit_id: action?.payload,
      };
    }

    case "PLAY_TRACK_ID": {
      return {
        ...state,
        playTrackID: action?.payload,
      };
    } //
    case "FINAL_CHANGE_VOLUME": {
      return {
        ...state,
        finalChangeVolume: action?.payload,
      };
    }
    case Casts.POST_CAST_CHANNEL: {
      let d = getLocal("cast_channel");
      let res = [JSON.parse(d)];
      res = [...res, action?.payload];

      const uniqueIds = [];
      const unique = res.filter((element) => {
        const isDuplicate = uniqueIds.includes(element?.cast_channel);

        if (!isDuplicate) {
          uniqueIds.push(element?.cast_channel);

          return true;
        }
        return false;
      });
      setLocal("cast_channel", JSON.stringify(unique));

      return {
        ...state,
        castChannel: action?.payload,
      };
    }

    case Casts.POST_CAST_GUESTS: {
      return {
        ...state,
        castChannelGuest: action?.payload,
      };
    }

    case Casts.GET_CAST_GUESTS_LIST: {
      return {
        ...state,
        getCastGuestLists: action?.payload,
      };
    } //

    case "MEMBER_LIST": {
      return {
        ...state,
        memberListPopUp: action?.payload,
      };
    }

    case "MEMBER_LIST_SHOW": {
      return {
        ...state,
        memberListPopUpShow: action?.payload,
      };
    }

    case "MEMBER_COUNT_SHOW": {
      return { ...state, memberStore: action.payload };
    }

    case "PRESENCE_ID": {
      return {
        ...state,
        presence_id: action?.payload,
      };
    }

    case "GUEST_JOINED_EVENT": {
      return { ...state, guestJoinedEvent: action.payload };
    }

    case "CAST_VOLUME": {
      return { ...state, castVolume: action.payload };
    }

    case "HOST_STOP_CAST": {
      return { ...state, hostStopCast: action.payload };
    }

    case "CAST_ON_OFF": {
      return { ...state, castOnOff: action.payload };
    }

    case "CAST_MOBILE_HISTORY": {
      return { ...state, castMobileHistory: action.payload };
    }

    case "NOW_PLAYING_CAST": {
      return { ...state, nowPlayingModal: action.payload };
    }

    case "DRAG_UNIQUE_ID": {
      return { ...state, drag_unique_id: action.payload };
    }

    case "NOW_PLAYING_LIST": {
      return { ...state, nowPlayingList: action.payload };
    }

    case "LHM_NOW_PLAYING_LIST": {
    /*   const uniqueIds = [];
      const unique = action?.payload.filter((element) => {
        const isDuplicate = uniqueIds.includes(
          element?.track?.trackOrigin === 'sidebar' ? element?.track?.id  :
          element?.track?.playlistID &&
            element?.track?.id &&
            element?.track?.sectionId
        );

        if (!isDuplicate) {
          uniqueIds.push(element?.track?.id);

          return true;
        }
        return false;
      }); */
      return { ...state, lhmNowPlayingList: action?.payload };
    }

    case "FINAL_LIST_OF_NOW_PLAYING": {
      return { ...state, finalNowPlayingList: action.payload };
    }

    case "HISTORY_CAST_EVENT": {
      return {
        ...state,
        castPusblishEvent: action?.payload,
      };
    }
    case "COMMON_VALUE": {
      return {
        ...state,
        commonValue: action?.payload,
      };
    }
    case "DESKTOP_COMMON_VALUE": {
      return {
        ...state,
        DesktopCommonValue: action?.payload,
      };
    }
    case "HANDLE_EVENT": {
      return {
        ...state,
        handleEvent: action?.payload,
      };
    }
    case "DESKTOP_HANDLE_EVENT": {
      return {
        ...state,
        desktopMenuHandleEvent: action?.payload,
      };
    }
    case "LEAVE_EVENT": {
      return {
        ...state,
        leaveEvent: action?.payload,
      };
    }

    case "PLAYING_FADE": {
      return {
        ...state,
        playingFade: action?.payload,
      };
    }

    case "CURRENT_FADE_IN_VOLUME": {
      return {
        ...state,
        currentFadeInVolume: action?.payload,
      };
    }

    case "DROP_TRACK_TO_PLAYLIST": {
      return {
        ...state,
        dropTrackToPlaylist: action?.payload,
      };
    }
    case playTimeUrl.GET_PLAY_TIME_URL_LOAD: {
      return {
        ...state,
        loading: true,
      };
    } //

    case "PARENT_FILE_UPLOAD": {
      return {
        ...state,
        parentFileUpload: action?.payload,
      };
    } //
    case "CURRENT_FILE_DATA": {
      return {
        ...state,
        currentFileData: [...state.currentFileData, ...action.payload],
      };
    }
    case "CURRENT_FILE_DATA_CHANGE": {
      return {
        ...state,
        currentFileData: action.payload,
      };
    }
    case "FILE_UPLOAD_ERROR": {
      return {
        ...state,
        fileUploadError: action.payload,
      };
    }
    case "REMOVE_ALL_FILE": {
      return {
        ...state,
        removeAllFile: [...state.removeAllFile, ...action.payload],
      };
    }

    case "UPLOAD_TRACKS_HEADER_DATA": {
      return {
        ...state,
        uploadTracksHeaderData: [
          ...state.uploadTracksHeaderData,
          ...action.payload,
        ],
      };
    }
    case "UPLOAD_TRACKS_HEADER_DATA_CHANGE": {
      return {
        ...state,
        uploadTracksHeaderData: action.payload,
      };
    }
    case "UPLOAD_TRACKS_PROGRESS": {
      return {
        ...state,
        uploadFileProgress: action.payload,
      };
    } //HEADER_PROGRESS
    case playTimeUrl.GET_PLAY_TIME_URL: {
      return {
        ...state,
        playTimeUrl: action?.payload,
      };
    }
    case playTimeUrl.GET_PLAY_TIME_URL_PEAKS: {
      return {
        ...state,
        playTimeUrlPeaks: action?.payload,
      };
    }
    case playTimeUrl.GET_PLAY_TIME_URL_LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        status: false,
        message: action.payload.errorMessage,
        error: true,
        insertError: true,
      };
    }

    case "HEADER_PROGRESS": {
      return {
        ...state,
        headerProgress: action.payload,
      };
    }
    case "UPLOAD_FILE_LENGTH": {
      return {
        ...state,
        uploadFileLength: action.payload,
      };
    } //
    case "FILE_UPLOAD_ALL_ERROR": {
      return {
        ...state,
        fileUploadAllError: action.payload,
      };
    }
    case Actions.GET_AVAILABLE_STORAGE: {
      return {
        ...state,
        getStorageData: action?.payload,
      };
    } //GET_AVAILABLE_STORAGE
    case "POST_TRACKS_UPLOAD": {
      return {
        ...state,
        tracksUpload: action?.payload,
      };
    }
    case "MINIMIZED_MAXIMIZED_PINNED": {
      return {
        ...state,
        minimizePinnedFlag: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
