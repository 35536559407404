import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { clarity } from "react-microsoft-clarity";
import CacheBuster from "react-cache-buster";
import LoadingBar from "react-top-loading-bar";
import { PersistGate } from "redux-persist/integration/react";
import { REACT_NODE_ENV } from "config";
import storeMaster from "./redux";
import App from "./App";
import { initLog } from "../src/common/utility/sentry";
import PackageJSON from "../package.json";

const { store, persistor } = storeMaster();
if (REACT_NODE_ENV === "production") {
  initLog(PackageJSON?.version);
  clarity.init("fv47fj9q4n");
  if (clarity.hasStarted()) {
    clarity.identify("USER_ID", { userProperty: "value" });
  }
}
const isProduction = REACT_NODE_ENV === "production";

ReactDOM.render(
  <React.StrictMode>
    {/* <CacheBuster
      currentVersion={PackageJSON?.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingBar color="#dcba6c" progress={0} height={4} />}//If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    > */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
{/*     </CacheBuster> */}
  </React.StrictMode>,
  document.getElementById("root")
);
