import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactChipInput from "react-chip-input";
import { useHistory, useLocation } from "react-router";
import { handleInviteUser } from "redux/action/auth";
import { useForceUpdate } from "common/function/function";
import "../auth.scss";

/* eslint-disable no-useless-escape */
const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const InviteUser = () => {
  const forceUpdate = useForceUpdate();
  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();
  const [chips, setChips] = useState([]);
  const [isValid, setIsValid] = useState(true);

  // validate email using email regrex
  const isValidEmail = (email) => {
    if (!email.match(emailReg)) {
      setIsValid(false);
      return false;
    } else {
      setIsValid(true);
      return true;
    }
  };

  const addChip = (value) => {
    if (isValidEmail(value)) {
      chips.slice();
      chips.push(value);
      const uniqueIds = [];
      const unique = chips.filter((element) => {
        const isDuplicate = uniqueIds.includes(element);

        if (!isDuplicate) {
          uniqueIds.push(element);

          return true;
        }
        return false;
      });
      setChips(unique);
      forceUpdate();
    }
  };

  const removeChip = (index) => {
    chips.slice();
    chips.splice(index, 1);
    setChips(chips);
  };

  const addUser = async () => {
    console.log("chips", chips?.join(","));
    if (chips?.length) {
      let res = await dispatch(handleInviteUser(chips?.join(","), history));
      if (res) setChips([]);
    }
  };

  return (
    <div className="modalSignIn">
      <div
        className="modal-content"
        style={{
          maxWidth: location?.pathname === "/invite-user" ? "550px" : "",
        }}
      >
        <div className="modal-body ">
          <div className="form-group-auth">
            <div className="text-left">
              <label className="mb-5 fs-14">Multiple Email Address </label>
              <ReactChipInput
                chips={chips}
                onSubmit={(value) => addChip(value)}
                onRemove={(index) => removeChip(index)}
              />
              {!isValid ? (
                <p className="text-danger mb-5">
                  Please enter proper email address
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group-auth pointer-event">
            <div className="d-flex">
              <button
                className="button button-yellow radius-8px w-100"
                type="button"
                onClick={() => addUser()}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteUser;