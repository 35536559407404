import {
  postAddExistingPlaylist,
  postSectionPlaylistUpdateOrder,
} from "common/services/section-playlist/services";

export const ADD_EXISTING_PLAYLIST_TO_SECTION_PENDING =
  "ADD_EXISTING_PLAYLIST_TO_SECTION_PENDING";
export const ADD_EXISTING_PLAYLIST_TO_SECTION_REJECTED =
  "ADD_EXISTING_PLAYLIST_TO_SECTION_REJECTED";
export const ADD_EXISTING_PLAYLIST_TO_SECTION_FULFILLED =
  "ADD_EXISTING_PLAYLIST_TO_SECTION_FULFILLED";

export const UPDATE_SECTION_PLAYLIST_ORDER_PENDING =
  "UPDATE_SECTION_PLAYLIST_ORDER_PENDING";
export const UPDATE_SECTION_PLAYLIST_ORDER_REJECTED =
  "UPDATE_SECTION_PLAYLIST_ORDER_REJECTED";
export const UPDATE_SECTION_PLAYLIST_ORDER_FULFILLED =
  "UPDATE_SECTION_PLAYLIST_ORDER_FULFILLED";

export function handleAddExistingPlaylist(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_EXISTING_PLAYLIST_TO_SECTION_PENDING });
      const response = await postAddExistingPlaylist(values);

      dispatch({
        type: ADD_EXISTING_PLAYLIST_TO_SECTION_FULFILLED,
        payload: response,
      });
    } catch (error) {
      console.error("error", error);
      dispatch({
        type: ADD_EXISTING_PLAYLIST_TO_SECTION_REJECTED,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleUpdateSectionPlaylistOrder({
  playlist,
  sectionPlaylistId,
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SECTION_PLAYLIST_ORDER_PENDING });
      await postSectionPlaylistUpdateOrder({ playlist });

      dispatch({
        type: UPDATE_SECTION_PLAYLIST_ORDER_FULFILLED,
        payload: { playlist, sectionPlaylistId },
      });
    } catch (error) {
      console.error("error", error);
      dispatch({
        type: UPDATE_SECTION_PLAYLIST_ORDER_REJECTED,
        payload: { errorMessage: error.message },
      });
    }
  };
}
