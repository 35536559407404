import * as Actions from "redux/action/auth/index";
import * as Section from "redux/action/section/index";
import * as Casts from "redux/action/cast/index";
import { setLocal, removeLocal, setCookie } from "common/function/storage";
const initialState = {
  authStore: {},
  token: "",
  fullname: "",
  stopAll: false,
  accountUpdate: {},
  sectionShow: {
    action: "inActiveSection",
  },
  castFlag: false,
  castLink: "",
  sidebarTracks: false,
  sectionList: [],
  castOnOff: false,
  memberStore: "",
  joinName: "",
  castHistorySidebar: [],
  pinnedLevel: 0,
};

export default function memorize(state = initialState, action) {
  switch (action.type) {
    case "SECTION_SHOW": {
      return {
        ...state,
        sectionShow: { ...state.sectionShow, ...action.payload },
      };
    }
    case "TOKEN": {
      return { ...state, token: action.payload };
    }
    case "USERNAME": {
      return { ...state, fullname: action.payload };
    }
    case "STOPALL_MODAL": {
      return { ...state, stopAll: action.payload };
    }

    case "CAST_ON_OFF": {
      return { ...state, castOnOff: action.payload };
    }

    case "MEMBER_COUNT_SHOW": {
      return { ...state, memberStore: action.payload };
    }

    case Actions.POST_LOGIN: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
        already_logged_in: action?.payload?.already_logged_in,
        bb_user_id: action?.payload?.bb_user_id,
        connected_to_bb: action?.payload?.connected_to_bb,
        public_id: action?.payload?.public_id,
      };
      setLocal("login_user", JSON.stringify(userDetails));
      setCookie("login_user", JSON.stringify(userDetails));

      return { ...state, authStore: action?.payload };
    }

    case Section.GET_SECTION: {
      return {
        ...state,
        sectionShow: { ...state.sectionShow, action: "" },
        loader: false,
      };
    }

    case Actions.POST_GOOGLE_LOGIN:
    case Actions.POST_FACEBOOK_LOGIN: {
      return { ...state, authStore: action?.payload };
    }
    case Section.POST_SECTION: {
      return { ...state, sectionShow: { ...state.sectionShow, action: "" } };
    }
    case Section.POST_ACCOUNT_SETTING: {
      const userDetails = {
        fullname: action?.payload?.fullname,
        email: action?.payload?.email,
      };
      removeLocal("login_user");

      setLocal("login_user", JSON.stringify(userDetails));

      return { ...state, authStore: action?.payload };
    }
    case "CAST_FLAG": {
      return { ...state, castFlag: action?.payload };
    }

    case "CAST_LINK": {
      return { ...state, castLink: action?.payload };
    }

    case Casts.POST_CAST: {
      return {
        ...state,
        cast: action?.payload,
      };
    }

    case "joinName": {
      return {
        ...state,
        joinName: action?.payload,
      };
    }

    case "CAST_SIDEBAR_HISTORY": {
      return {
        ...state,
        castHistorySidebar: action?.payload,
      };
    }
    case "PINNED_LEVEL": {
      return {
        ...state,
        pinnedLevel: action?.payload,
      };
    }

    default: {
      return state;
    }
  }
}
