// constants
export const ENVIRONMENT = "local";
export const API_BASE_URL = process.env.REACT_APP_BATTALEBARDS_API_URL;
export const API = process.env.REACT_APP_BATTALEBARDS_API_URL;
export const SOCKET = process.env.REACT_APP_BATTALEBARDS_SOCKET_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
export const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const REACT_APP_PUBLISH_KEY = process.env.REACT_APP_PUBLISH_KEY;
export const REACT_APP_SUBSCRIBE_KEY = process.env.REACT_APP_SUBSCRIBE_KEY;
export const REACT_APP_SECRETE_KEY = process.env.REACT_APP_SECRETE_KEY;
export const REACT_APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const REACT_APP_MAX_FILE_SIZE_BYTES =
  process.env.REACT_APP_MAX_FILE_SIZE_BYTES;
export const REACT_APP_AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
export const REACT_NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_FIREBASE_APP_KEY = process.env.REACT_APP_FIREBASE_APP_KEY
export const REACT_APP_FIREBASE_PATH_DOMAIN = process.env.REACT_APP_FIREBASE_PATH_DOMAIN
export const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const REACT_APP_FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
export const REACT_APP_IS_UNDER_MAINTENANCE = process.env.REACT_APP_IS_UNDER_MAINTENANCE
export const REACT_APP_MAINTENANCE_MESSAGE = process.env.REACT_APP_MAINTENANCE_MESSAGE


