import React from "react";
import { Route } from "react-router-dom";
import { REACT_APP_WEBSITE_URL } from "config";
import { getCookie } from "common/function/storage";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let redirectWebsite = REACT_APP_WEBSITE_URL ? REACT_APP_WEBSITE_URL + '/auth/signin' : "https://development.store.battlebards.dev"

  const _TOKEN = getCookie("access_token");
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        _TOKEN ? <Component {...props} /> : 
        window.open(redirectWebsite, "_self")
       /*  <Redirect to="sign-in" /> */
      }
    />
  );
}

export default ProtectedRoute;
