const initialState = {
  tracks: {},
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case "PLAY_TRACK": {
      const playerId = action.payload.playerId;
      const isMute = action.payload.isMute;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [playerId]: {
            ...state.tracks[playerId],
            isPlaying: true,
            isMute: isMute,
            id: playerId,
          },
        },
      };
    }

    case "PAUSE_TRACK": {
      const playerId = action.payload.playerId;
      const isMute = action.payload.isMute;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [playerId]: {
            ...state.tracks[playerId],
            isPlaying: false,
            isMute: isMute,
          },
        },
      };
    }

    case "STOP_TRACK": {
      const playerId = action.payload.playerId;
      const newTracks = { ...state.tracks };
      delete newTracks[playerId];
      return {
        ...state,
        tracks: newTracks,
      };
    }

    case "STOP_ALL_PLAYING_TRACKS": {
      return {
        ...state,
        tracks: {},
      };
    }

    case "UPDATE_PLAYER_DURATION": {
      const playerId = action.payload.playerId;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [playerId]: {
            ...state.tracks[playerId],
            currentTime: action.payload.currentTime,
          },
        },
      };
    }

    case "VOLUME_UPDATE": {
      const playerId = action.payload.playerId;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [playerId]: {
            ...state.tracks[playerId],
            volume: action.payload.volume,
            muted: action.payload.muted,
          },
        },
      };
    }

    case "VOLUME_UPDATE_SIDEBAR": {
      const playerId = action.payload.playerId;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [playerId]: {
            ...state.tracks[playerId],
            volume: action.payload.volume,
            muted: action.payload.muted,
          },
        },
      };
    }

    case "MOVE_PLAYER": {
      const from = action.payload.from;
      const to = action.payload.to;
      const currentTime = action.payload.currentTime;
      const newTracks = { ...state.tracks };
      newTracks[to] = {
        ...newTracks[from],
        currentTime,
        updateCurrentTime: Date.now(),
      };
      delete newTracks[from];
      return {
        ...state,
        tracks: newTracks,
      };
    }

    default: {
      return state;
    }
  }
}
