import axios from "axios";
import { axiosInstance } from "common/axiosConfig";
import { showToast } from "common/function/function";
import { errorLog } from "common/utility/utils";
import { API_BASE_URL } from "config";
/* Simple Login User */
export const postLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/login`, value)
      .then((response) => {
        resolve(response.data.data);
        if (!response?.data?.data?.already_logged_in)
          showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
        resolve("error");
      })
  );

/* Simple Login User */
export const replacePostLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/replace-login`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
/* Get Verify user  */

export const getAuthVerify = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/verify/${value}`)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Get Verify Register user  */

export const getAuthRegisterVerify = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/verify-user/${value}`)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Register user  */
export const postSignUp = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/register`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Login with facebook */

export const postFacebookLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/facebookOauth`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Login with Google */
export const postGoogleLogin = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/googleOauth`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Reset Password */
export const postResetPassword = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/reset-password`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Forgot password */
export const getForgotPassword = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/forgot-password/${value}`)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Resend mail for verification */
export const getSendMail = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/resend-verification/${value}`)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Logout User */
export const getLogout = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`auth/logout`)
      .then((response) => {
        resolve(response && response.data && response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Multiple User Invite*/
export const multipleInviteUser = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`auth/register-multiple-verified`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const refreshAccessToken = (params) =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_BASE_URL}auth/refresh`, { refresh_token: params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error);
      })
  );
