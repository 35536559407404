import { getPlayTimeUrl, generatePeekAPI, PostSQS} from "common/services/play_time/services";

export const GET_PLAY_TIME_URL_LOAD = "[PLAYTIMEURL] GET_PLAY_TIME_URL_LOAD";
export const GET_PLAY_TIME_URL = "[PLAYTIMEURL] GET_PLAY_TIME_URL";
export const GET_PLAY_TIME_URL_PEAKS = "[PLAYTIMEURL] GET_PLAY_TIME_URL_PEAKS";
export const GET_PLAY_TIME_URL_LOAD_ERROR =
  "[PLAYTIMEURL] GET_PLAY_TIME_URL_LOAD_ERROR";

export function handlePlayTimeUrl(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD,
        payload: true,
      });
      const response = await getPlayTimeUrl(value);
      dispatch({
        type: GET_PLAY_TIME_URL,
        payload: response?.presignedurl,
      });
      dispatch({
        type: GET_PLAY_TIME_URL_PEAKS,
        payload: response?.peaks,
      });
      return response?.presignedurl;
    } catch (error) {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}


//Generated Peaks
export function handleGeneratePeeks(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD,
        payload: true,
      });
      const response = await generatePeekAPI(value);
      return response;
    } catch (error) {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handlePostSQS(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD,
        payload: true,
      });
      const response = await PostSQS(value);
      return response;
    } catch (error) {
      dispatch({
        type: GET_PLAY_TIME_URL_LOAD_ERROR,
        payload: { errorMessage: error?.message },
      });
    }
  };
}

