import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer } from "react-toastify";
import Ping from "ping.js";
import { PubNubProvider } from "pubnub-react";
import { handleCastGuestList } from "redux/action/cast";
import { REACT_APP_IS_UNDER_MAINTENANCE, REACT_APP_WEBSITE_URL } from "config";
import "./App.scss";
import "./assets/icon/icomoon-css.css"

import usePubNubStore from "common/hooks/pubnubStore";
import { getCookie } from "common/function/cookie";
import AnalyticsContext from "common/contexts/analytics";
import useAnalyticsStore from "common/hooks/useAnalyticsStore";

import { lazyRetry, onlineOfflineStatus } from "common/function/function";
import { REACT_NODE_ENV } from "config";
import ProtectedRoute from "./protected-route";
import InviteUser from "./component/auth/invite-user";
/* const ResetPassword = React.lazy(() =>
  lazyRetry(() => import("./component/auth/reset-password"))
);

const SendMail = React.lazy(() =>
  lazyRetry(() => import("./component/auth/send-mail"))
); */
const MainSection = React.lazy(() =>
  lazyRetry(() => import("./component/section"))
);
// const CorePlayer = React.lazy(() => import("./component/core-player/"));
const Info = React.lazy(() => lazyRetry(() => import("./component/info")));

const PlayList = React.lazy(() =>
  lazyRetry(() => import("./component/playlist"))
);
/* const SignIn = React.lazy(() =>
  lazyRetry(() => import("./component/auth/sign-in/index"))
);
const SignUp = React.lazy(() =>
  lazyRetry(() => import("./component/auth/sign-up/index"))
);
const Account = React.lazy(() =>
  lazyRetry(() => import("./component/auth/account"))
); */

const Cast = React.lazy(() => lazyRetry(() => import("component/cast")));
const _TOKEN = getCookie("access_token");
/* const ForgotPassword = React.lazy(() =>
  import("./component/auth/forgot-password/index")
); */

//Usernames based on time joined
// const now = new Date().getTime();
// const username = ["user", now].join("-");
function App() {
  const openMember = useSelector((e) => e?.common?.memberListPopUp);
  const _CAST_CHANNEL = useSelector((e) => e.common.castChannel);
  const _LEAVE_EVENT_FIRE = useSelector((e) => e.common.leaveEvent);

  const _CAST_CHANNEL_GUEST = useSelector((e) => e.common.castChannelGuest);
  const _CAST_ON_OFF = useSelector((e) => e.memorize.castOnOff);

  let parseDetails = getCookie("login_user")
    ? JSON.parse(getCookie("login_user"))
    : null;

    console.log(';parseDetails', parseDetails)
  const _AUTH_STORE = parseDetails;

  const _CAST_PUBLISH_EVENT = useSelector((e) => e.common.castPusblishEvent);
  const _NOW_PLAYING_EVENT = useSelector((e) => e.common.nowPlayingList);

  const _PRESENCE_ID = useSelector((e) => e.common.presence_id);
  const GUEST_JOINED_EVENT = useSelector((e) => e.common.guestJoinedEvent);

  const [progress, setProgress] = useState(80);

  const pubnub = usePubNubStore();
  const analytics = useAnalyticsStore();

  const dispatch = useDispatch();

  if(REACT_NODE_ENV === "production") {
  console.log=()=> {}
  console.warn=()=> {}
  console.error=()=> {}
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(progress + 10);
    }, 800);
    onlineOfflineStatus();
    return () => clearInterval(interval);
  }, []);

  const subscribeCast = () => {
    // let dC = "BB-" + _CAST_CHANNEL?.cast_channel;
    pubnub.subscribeMessage(_CAST_CHANNEL?.cast_channel);
  };

  useEffect(async () => {
    if (_CAST_CHANNEL?.cast_channel && _TOKEN) {
      let uuidMetaData = await pubnub.objects.getUUIDMetadata();
      if (uuidMetaData?.data?.custom?.isHost) {
        return;
      } else if (_CAST_CHANNEL?.cast_title !== _AUTH_STORE?.fullname) {
        await pubnub.objects.setUUIDMetadata({
          data: {
            name: _AUTH_STORE?.fullname,
          },
        });
        subscribeCast();
      }
    }
  }, [_CAST_CHANNEL]);

  /* useEffect(() => {
    if (_CAST_CHANNEL?.id && _CAST_CHANNEL_GUEST) {
      let params = {
        id: _CAST_CHANNEL?.id,
      };
      dispatch(handleCastGuestList(params));
    }
  }, [_CAST_CHANNEL, _CAST_CHANNEL_GUEST]); */

  useEffect(async () => {
    const parseDetailsChannel = getCookie("cast_channel")
      ? JSON.parse(getCookie("cast_channel"))
      : null;

    const cast_channel = parseDetailsChannel;

    let params = {
      id: _CAST_CHANNEL?.id
        ? _CAST_CHANNEL?.id
        : cast_channel?.find((item) => item?.cast_channel)?.id,
    };
    if (_CAST_ON_OFF || _LEAVE_EVENT_FIRE || GUEST_JOINED_EVENT) {
      pubnub.hereNow(
        {
          channels: [
            _CAST_CHANNEL?.cast_channel
              ? _CAST_CHANNEL?.cast_channel
              : cast_channel?.find((item) => item?.cast_channel)?.cast_channel,
          ],
        },
        async (status, response) => {
          if (!status?.error && params?.id) {
            await dispatch(handleCastGuestList(params));
            dispatch({
              type: "MEMBER_COUNT_SHOW",
              payload:
                response?.channels[
                  _CAST_CHANNEL?.cast_channel
                    ? _CAST_CHANNEL?.cast_channel
                    : cast_channel?.find((item) => item?.cast_channel)
                        ?.cast_channel
                ],
            });
          }
        }
      );
    }
  }, [
    _CAST_CHANNEL_GUEST,
    _CAST_CHANNEL,
    _PRESENCE_ID,
    openMember,
    _LEAVE_EVENT_FIRE,
    GUEST_JOINED_EVENT,
  ]);

  useEffect(() => {
    if (
      (_CAST_PUBLISH_EVENT?.event === "Play" ||
        _CAST_PUBLISH_EVENT?.event === "LHMPlay" ||
        _CAST_PUBLISH_EVENT?.event === "Volume_Change" ||
        _CAST_PUBLISH_EVENT?.event === "LHMVolume" ||
        _CAST_PUBLISH_EVENT?.event === "isMute" ||
        _CAST_PUBLISH_EVENT?.event === "isLoop") &&
      _CAST_PUBLISH_EVENT.playing
    ) {
      let findItem = _NOW_PLAYING_EVENT?.findIndex(
        (item) => item?.trackId === _CAST_PUBLISH_EVENT?.trackId
      );
      let newNowPlaying;
      if (findItem !== -1) {
        newNowPlaying = _NOW_PLAYING_EVENT?.map((item) => {
          let newItem = item;
          if (item?.trackId === _CAST_PUBLISH_EVENT?.trackId) {
            newItem = { ...item, _CAST_PUBLISH_EVENT };
          }
          return newItem;
        });
      } else {
        newNowPlaying = [..._NOW_PLAYING_EVENT, _CAST_PUBLISH_EVENT];
      }
      dispatch({ type: "NOW_PLAYING_LIST", payload: newNowPlaying });
    } else if (_CAST_PUBLISH_EVENT?.event === "trackDelete") {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        let str = _CAST_PUBLISH_EVENT?.playlistID.substring(1);
        let checkFlag = item?.trackId?.split("-")[0] === 'pinned' ? item?.trackId?.split("-")[1]?.match(/\d+/g)[0] :  item?.trackId?.split("-")[0];
        let splitPlaylistID = checkFlag;
        return splitPlaylistID !== str;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    } else if (_CAST_PUBLISH_EVENT?.event === "playlistDeletePinned") {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        let str = _CAST_PUBLISH_EVENT?.playlistID.substring(1);
        let splitPlaylistID = item?.trackId?.split("-")[2];
        return splitPlaylistID !== str;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    } else if (_CAST_PUBLISH_EVENT?.event === "playlistDelete") {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        let str = _CAST_PUBLISH_EVENT?.playlistID.substring(1);
        let checkFlag = item?.trackId?.split("-")[0] === 'pinned' ? item?.trackId?.split("-")[2] : item?.trackId?.split("-")[1];
        let splitPlaylistID = checkFlag;
        return splitPlaylistID !== str;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    } else if (_CAST_PUBLISH_EVENT?.event === "sectionDelete") {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        return item?.sectionId !== _CAST_PUBLISH_EVENT?.sectionId;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    } else if (_CAST_PUBLISH_EVENT?.event === "mainPlaylistDelete") {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        return item?.mainPlaylistId !== _CAST_PUBLISH_EVENT?.mainPlaylistId;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    } else if (_CAST_PUBLISH_EVENT?.event === "stopAll") {
      dispatch({ type: "NOW_PLAYING_LIST", payload: [] });
    } else if (
      _CAST_PUBLISH_EVENT?.event === "Pause" ||
      _CAST_PUBLISH_EVENT?.event === "Stop" ||
      _CAST_PUBLISH_EVENT?.event === "LHMStop"
    ) {
      let arr = _NOW_PLAYING_EVENT.filter(function (item) {
        return item?.trackId !== _CAST_PUBLISH_EVENT?.trackId;
      });
      dispatch({ type: "NOW_PLAYING_LIST", payload: arr });
    }
  }, [_CAST_PUBLISH_EVENT]);

  useEffect(() => {
    var p = new Ping();

    p.ping("https://beta.battlebards.com/", function (err, data) {
      // Also display error if err is returned.
      if (err) {
        console.log("error loading resource");
        data = data + " " + err;
      }
      console.log("Load packets", data);
      // document.getElementById("ping-google").innerHTML = data;
    });
  });

  //invite user regex
  /* eslint-disable no-useless-escape */
  const PassRegex = /^[-_A-Za-z0-9\.]+@battlebards\.com$/g;

  useEffect(() => {
    if (REACT_APP_IS_UNDER_MAINTENANCE == "true") {
      window.open(REACT_APP_WEBSITE_URL + "/maintenance", "_self");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="main-wrapper">
        <AnalyticsContext.Provider value={analytics}>
          <PubNubProvider client={pubnub}>
            <Suspense
              fallback={
                <LoadingBar
                  color="#dcba6c"
                  progress={progress}
                  onLoaderFinished={() => setProgress(0)}
                  height={4}
                />
              }
            >
              <Router>
                <Switch>
                  <ProtectedRoute exact path={`/`} component={MainSection} />
                  <Route exact path={`/cast/:id`} component={Cast} />
                  <ProtectedRoute
                    exact
                    path={`/playlist`}
                    component={PlayList}
                  />
                  <ProtectedRoute exact path={`/info`} component={Info} />
                  {/*    <ProtectedRoute exact path={`/account`} component={Account} /> */}
                  {/* <Route exact path={`/sign-in`} component={SignIn} />
                  <Route exact path={"/sign-in/:id"} component={SignIn} />
                  <Route exact path={`/sign-up`} component={SignUp} />
                  <Route exact path={`/sign-up/:id`} component={SignUp} />
                  <Route
                    exact
                    path={`/forgot-password`}
                    component={ForgotPassword}
                  />
                  <Route exact path={`/send-mail`} component={SendMail} />
                  <Route
                    exact
                    path={`/reset-password/:id`}
                    component={ResetPassword}
                  /> */}
                  {PassRegex.test(_AUTH_STORE?.email) ? (
                    <ProtectedRoute
                      exact
                      path={`/invite-user`}
                      component={InviteUser}
                    />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Switch>
              </Router>
              <ToastContainer autoClose={500} />
            </Suspense>
          </PubNubProvider>
        </AnalyticsContext.Provider>
      </div>
    </React.Fragment>
  );
}

export default App;
