import log from "loglevel";

import * as Sentry from "@sentry/react";

import { CaptureConsole } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { REACT_NODE_ENV } from "config";
/**
 * It initializes the Sentry SDK.
 */
export const initLog = (version) => {
  Sentry.init({
    // dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DNS
      ? process.env.REACT_APP_SENTRY_DNS
      : "https://21d250c6a387441eb421083978e96737@o154616.ingest.sentry.io/6548934",
    /* A way to capture the console.logs and send them to Sentry. */
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        defaults: ["error"],
        levels: ["error"],
      }),
    ],
    release: `soundboard@${REACT_NODE_ENV} ${version}`,
    //tracesSampleRate: 1.0,
  });
};

export const infoLog = () => {
  log.init("init value");
};

export const errorLog = (message, attributes, filename, methodname) => {
  log.error({
    message,
    attributes,
    filename,
    methodname,
  });
};

export const debugLog = (message, attributes, filename, methodname) => {
  log.debug({
    message,
    attributes,
    filename,
    methodname,
  });
};

export const userLoggedInLog = (message, attributes, filename, methodname) => {
  log.warn({
    message,
    attributes,
    filename,
    methodname,
  });
};
