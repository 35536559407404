/* eslint-disable no-undef */
import { useState, useCallback, useEffect } from "react";

import { toast } from "react-toastify";
import AWS from "aws-sdk";
import { Sha256 } from "@aws-crypto/sha256-browser";
import byteSize from "byte-size";
import CryptoJS from "crypto-js"; /* CryptoJS */
import { REACT_APP_AWS_REGION } from "config";

import "react-toastify/dist/ReactToastify.css";
import { removeLocal } from "./storage";
import { eraseCookie } from "./cookie";

export const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
    .filter((a) => a)
    .join(":");
};

export function getIsMobile() {
  const width = typeof window !== "undefined" ? window.innerWidth : null;
  const isMobile = width <= 768;
  return isMobile;
}

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const uniqueId = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export function useForceUpdate() {
  const [, setUpdate] = useState(0);
  const update = useCallback(() => {
    setUpdate((update) => update + 1);
  }, []);
  return update;
}

export const Decryption = (data) => {
  try {
    if (process.env.REACT_APP_ENC_KEY) {
      const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENC_KEY);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else return data;
  } catch (e) {
    return null;
  }
};
export const Encryption = (data) => {
  if (process.env.REACT_APP_ENC_KEY)
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENC_KEY
    ).toString();
  else return data;
};

export const showToast = (status, msg, type) => {
  toast.success(
    <div className="pl-15">
      <h1 className="fs-18">{status}</h1>
      <p className="fs-16">{msg}</p>
    </div>,
    {
      icon: () =>
        type === "success" ? (
          <i
            className="icon-check-alt fa-lg toast-success toster-icon"
            aria-hidden="true"
          />
        ) : (
          <i
            className="icon-close-outline fa-lg text-danger toster-icon"
            aria-hidden="true"
          />
        ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // type: type,
    }
  );
};

// Out side click
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

// truncate Name
export const truncate = (source, size) =>
  source?.length > size ? source?.slice(0, size - 1) + "…" : source;

// DuplicatePlaylist Name
export const checkDuplicatePlaylist = async (playlist, params) => {
  let existingPlaylist = 0;
  playlist?.forEach(({ playlists }) => {
    let existing = playlists.filter(
      (playlist) =>
        playlist.playlist_id !== params.id && playlist?.name === params.name
    );
    if (existing.length) existingPlaylist++;
  });
  return existingPlaylist;
};

// react-select getLabelValue for option for Add to playlist or Create Playlist
export const getLabelValue = (arrName) => {
  const finalArray = [];
  arrName?.forEach((e) => finalArray?.push({ label: e.section, value: e.id }));
  return finalArray;
};

// react select get key-value for default value
export const getKeyName = (arrList, id) => {
  if (arrList && arrList.length) return arrList.find((_) => _.id === id);
};

export const getKeyLabel = (arrList, id) => {
  const finalArray = [];
  if (arrList && arrList.length)
    arrList?.forEach((e) => {
      if (e?.id === id) finalArray?.push({ label: e.section, value: e.id });
    });
  return finalArray;
};

// react-select getLabelValue for option for add track to playlist in L.H.M
export const getLabelValueTrack = (arrName) => {
  const finalArray = [];
  arrName &&
    arrName?.forEach((e) => finalArray?.push({ label: e.name, value: e.id }));
  return finalArray;
};

// style for react-select
export const colourStyles = {
  placeholder: (base) => ({
    ...base,
    // backgroundColor: "black",
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#717171",
  }),
  clearIndicator: (prevStyle) => ({
    ...prevStyle,
    color: "#717171",
    ":hover": {
      color: "#dcba6c",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px", // your desired height
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused && "#fff",
    backgroundColor: "#333",
    minHeight: "40px",
    cursor: "pointer",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    "&:hover": {
      color: "#dcba6c",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "10px 0",
  }),
  input: (base) => ({
    ...base,
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#333",
    textColor: "#fff",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    width: "100%",
    zIndex: "9999",
    fontSize: "13px",
    marginTop: "-5px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state?.data?.__isNew__ === true
        ? "#dcba6c"
        : state.isFocused
        ? "#dcba6c"
        : "#333",
    color: state.isFocused && "#222222",
    ":active": {
      backgroundColor: "#dcba6c"
        ? state.isFocused
          ? "#dcba6c"
          : "#dcba6c"
        : undefined,
    },
    cursor: "pointer",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    zIndex: "9999",
    fontSize: "13px",
    position: "relative",
    top: "-5px",
  }),
};

export const region = REACT_APP_AWS_REGION || "us-east-2";
export const getS3Credentail = () => {
  const s3 = new AWS.S3({
    accessKeyId: "AKIAWT3HF4WYURU4BRYL",
    secretAccessKey: "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",
    region,
    sha256: Sha256,
  });
  return s3;
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  const ret = function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
  ret.cancel = () => clearTimeout(lastFunc);
  return ret;
};

export const debounce = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const setImmediate = (func) => {
  if (typeof window.setImmediate === "undefined") {
    setTimeout(func, 0);
  } else {
    window.setImmediate(func);
  }
};

export const removeDuplicates = (arrayName, dispatch) => {
  var result = arrayName.reduce((unique, o) => {
    if (!unique.some((obj) => obj.trackId === o.trackId)) {
      unique.push(o);
    }
    return unique;
  }, []);
  // Display the unique objects
  dispatch({ type: "FINAL_LIST_OF_NOW_PLAYING", payload: result });
};

//background glow color playing state true onClick

export const removeDomElementChildren = (domElement) => {
  while (domElement.lastElementChild) {
    domElement.removeChild(domElement.lastElementChild);
  }
};

export const removeChildNodes = (element) => {
  while (element.lastChild) {
    element.removeChild(element.lastChild);
  }
};

//reverse array
export const reverseArray = (array) =>
  array && array.map((item, idx) => array[array.length - 1 - idx]);

//Multi select Remove from l.h.m playlist
export const removeMultiSelect = (
  name,
  _PLAYLIST,
  track,
  _TRACKS_LIST_CONTROLLER,
  playlist_search,
  _PLAYLIST_SEARCH,
  track_list,
  _TRACK_LIST,
  dispatch
) => {
  let data =
    _PLAYLIST?.data?.length &&
    _PLAYLIST?.data?.map((item) => {
      item?.tracks?.forEach((ele) => {
        ele.multiSelectFlag = false;
      });
      return item;
    });
  dispatch({
    type: "[SECTION] GET_PLAYLIST",
    payload: { data: data },
  });

  let item =
    _TRACKS_LIST_CONTROLLER?.length &&
    _TRACKS_LIST_CONTROLLER?.map((ele) => {
      ele.albums?.forEach((alb) => {
        alb?.tracks?.forEach((tracksElem) => {
          tracksElem.multiSelectFlag = false;
        });
        return alb;
      });
      return ele;
    });
  dispatch({
    type: "[SECTION] GET_TRACKLIST_CONTROLLER",
    payload: item,
  });

  let data1 = _PLAYLIST_SEARCH?.data?.map((playlist) => {
    playlist?.tracks?.forEach((tracksElem) => {
      tracksElem.multiSelectFlag = false;
      return tracksElem;
    });
    return playlist;
  });

  dispatch({
    type: "[SECTION] GET_PLAYLIST_SEARCH",
    payload: { data: data1 },
  });

  let item1 =
    _TRACK_LIST?.length &&
    _TRACK_LIST?.map((ele) => {
      ele.multiSelectFlag = false;
      return ele;
    });
  dispatch({
    type: "[SECTION] GET_TRACKLIST",
    payload: item1,
  });
};

export const spliceLhmNowPlaying = (nowPlayingList, currentTrack, dispatch) => {
  let index = nowPlayingList?.findIndex((item) => {
    if ("album_id" in currentTrack) {
      let parentCheck =
        "album_id" in currentTrack &&
        item.track.album_id === currentTrack.album_id;
      return item.track.track_id === currentTrack.track_id && parentCheck;
    }
    if ("playlist_id" in currentTrack) {
      if (currentTrack?.trackOrigin === "sidebar") {
        let parentCheck =
          "playlist_id" in currentTrack && item.track.id === currentTrack.id;
        return item.track.track_id === currentTrack.track_id && parentCheck;
      } else if (currentTrack?.trackOrigin === "soundboard" || currentTrack?.trackOrigin === "pinned-playlist") {
        let parentCheck =
          item?.track?.playlistID === currentTrack?.playlistID &&
          item.track.id === currentTrack.id;
        return item.track.track_id === currentTrack.track_id && parentCheck;
      }
    }
    return item;
  });
  const storeData = nowPlayingList && [...nowPlayingList];
  const tempArray = [...storeData];
  if (index >= 0) {
    tempArray.splice(index, 1);
    dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: tempArray });
  }
};

export const deletePlaylistNowPlayingRemove = (
  nowPlayingList,
  id,
  dispatch
) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.playlistID !== id;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const deleteTrackRemovePinned = (nowPlayingList, dispatch) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.trackOrigin !== 'pinned-playlist';
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const deleteSectionNowPlayingRemove = (nowPlayingList, id, dispatch) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.sectionId !== id;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const deleteTrackRemove = (nowPlayingList, id, dispatch) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.id !== id;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const deleteUploadTrack = (nowPlayingList, id, dispatch) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.track_id !== id;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const deleteLHMNowPlayingRemove = (nowPlayingList, id, dispatch) => {
  let arr = nowPlayingList.filter(function (item) {
    return item?.track?.playlist_id !== id;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: arr });
};

export const findVolumeUpdateNowPlaying = (
  nowPlayingArray,
  updateValue,
  isMutes,
  trackId,
  dispatch,
  playlistID
) => {
  let tempArray = nowPlayingArray?.map((items) => {
    if (
      (items?.track?.trackOrigin === "soundboard" || items?.track?.trackOrigin === "pinned-playlist") &&
      items?.track?.id === trackId &&
      items?.track?.playlistID === playlistID
    ) {
      items.track.volume = updateValue;
      items.track.muted = isMutes;
    }
    return items;
  });
  dispatch({ type: "LHM_NOW_PLAYING_LIST", payload: tempArray });
};

export const getSoundAndFadeAudio = (
  wave,
  quickFadeCount,
  volume,
  playing,
  eventString,
  currentStopTime,
  _CURRENT_FADE_IN_VOLUME,
  dispatch
) => {
  var vol = volume / 100;
  if (vol === 0 && wave?.getVolume() === 0) {
    return;
  }

  //const fadePoint = wave?.getCurrentTime() - quickFadeCount;
  const diffFI = parseInt(quickFadeCount - wave?.getCurrentTime());
  // const diffFO = parseInt(wave?.getCurrentTime() - fadePoint);
  const volPart = volume / (quickFadeCount * 100);
  const volPartTest =
    _CURRENT_FADE_IN_VOLUME === ""
      ? vol
      : _CURRENT_FADE_IN_VOLUME / quickFadeCount;
  const temp = wave?.getCurrentTime() === 0 ? 0 : (volume * volPart) / 100;
  const TotalTime = currentStopTime + quickFadeCount;
  const diffFO = parseInt(TotalTime - wave?.getCurrentTime());

  // This works as long as you start with a multiple of 0.02,0.04,0.06!
  switch (eventString) {
    case "play": {
      if (diffFI > 0 && Number.isInteger(diffFI) && wave?.getCurrentTime()) {
        // Fade In
        const currentDurPart = quickFadeCount - diffFI + 1;
        let fadeInVol = currentDurPart * temp;
        fadeInVol =
          quickFadeCount === parseInt(wave?.getCurrentTime() + 1.1)
            ? vol
            : fadeInVol < vol
            ? fadeInVol
            : vol;
        dispatch({ type: "CURRENT_FADE_IN_VOLUME", payload: fadeInVol });
        wave?.setVolume(parseFloat(wave?.getVolume() === 0 ? 0 : fadeInVol));
      }
      break;
    }
    case "stop": {
      // Fade Out

      if (diffFO > 0 && Number?.isInteger(diffFO) && diffFO < quickFadeCount) {
        let fadeOutVol =
          parseInt(currentStopTime) < quickFadeCount
            ? volPartTest * diffFO
            : volPart * diffFO;
        fadeOutVol = fadeOutVol > 0 ? fadeOutVol : 0;
        wave?.setVolume(parseFloat(wave?.getVolume() === 0 ? 0 : fadeOutVol));
      }
      break;
    }
    default: {
      break;
    }
  }
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const removeCastStorage = (dispatch) => {
  removeLocal("joinName");
  eraseCookie("join");
  dispatch({ type: "CAST_ON_OFF", payload: false });
  dispatch({ type: "CAST_LINK", payload: "" });
  dispatch({ type: "PRESENCE_ID", payload: null });
  dispatch({
    type: "HISTORY_CAST_EVENT",
    payload: [],
  });
  dispatch({
    type: "FINAL_LIST_OF_NOW_PLAYING",
    payload: [],
  });
  dispatch({
    type: "ADD_MULTIPLE_TRACKS",
    payload: false,
  });
  dispatch({
    type: "MEMBER_COUNT_SHOW",
    payload: null,
  });

  dispatch({
    type: "[Casts] GET_CAST_GUESTS_LIST",
    payload: [],
  });

  dispatch({
    type: "[Casts] POST_CAST_GUESTS",
    payload: [],
  });
  dispatch({ type: "NOW_PLAYING_LIST", payload: [] });
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const sortFunc = (data) =>
  data?.sort(
    (a, b) => a?.is_lock - b?.is_lock || a?.lock_upload - b?.lock_upload
  );
export const byteSizeValue = (bytes) =>
  byteSize(bytes, { units: "iec" })?.value;

export const byteSizeUnit = (bytes) =>
  byteSize(bytes, { units: "iec" })?.unit.replace("i", "");

export const updateArrayKeyValue = (array, id, key) => {
  for (var i in array) {
    if (array[i].uuid === id) {
      array[i].error = key;
      break; //Stop this loop, we found it!
    }
  }
};

export const onlineOfflineStatus = () => {
  window.addEventListener("online", function (e) {
    if (navigator && navigator?.connection && navigator?.connection?.downlink < 5) {
    showToast("Failed !", "Slow internet connection!", "error");
    } else if (navigator && navigator?.connection && navigator?.connection?.downlink > 5) {
      showToast("Successfully !", "Back online!", "success");
    }
  });
  window.addEventListener("offline", function (e) {
    showToast("Failed !", "No internet connection!", "error");
  });
};

export const unixTimeStamp = () => Math.floor(new Date().getTime() / 1000);

export const togglePlayCheck = (data) => {
  let tData = {};

  for (const [key, value] of Object.entries(data)) {
    const temp = key.split("-");
    const arrKey = temp[1];
    const arrid = temp[0];

    const isKey = [arrKey] in tData;

    if (!isKey) {
      tData[arrKey] = [{ ...value, id: arrid }];
    } else {
      /* tData[arrKey] = tData[arrKey].push(value) */
      let oldData = tData[arrKey];
      oldData = [...oldData, { ...value, id: arrid }];
      tData[arrKey] = oldData;
    }
  }
  return tData;
};