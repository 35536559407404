import {
  getSectionSequance,
  getSectionList,
  postSection,
  getPlayList,
  postSectionPlaylist,
  getSectionPlaylistByID,
  getSectionPlaylistAll,
  deleteSectionList,
  editPlaylistName,
  deletePlaylist,
  deletePlaylistTrack,
  collaspePlaylist,
  togglePlayPlayList,
  pinningPlaylist,
  trackList,
  loopTrack,
  buttonStateTrack,
  volumeControlTrack,
  searchTrackList,
  trackToPlaylist,
  trackShuffle,
  addExistingToPlaylist,
  deletePlaylistByID,
  getSearchPlayList,
  editSectionName,
  buttonIconTrack,
  //Account Setting
  accountSetting,
  changePassword,
  trackTracksController,
  fadeInOutTrack,
  tracksUpload,
  removeUploadedFileFromS3,
  removeAllUploadedFileFromS3,
  availableS3Storage,
  deleteUploadTracks,
} from "common/services/section/services";

export const POST_SECTION_LOAD = "[SECTION] POST_SECTION_LOAD";
export const GET_SECTION = "[SECTION] GET_SECTION";
export const EDIT_SECTION = "[SECTION] EDIT_SECTION";
export const POST_SECTION_ERROR = "[SECTION] POST_SECTION_ERROR";
export const PUT_SECTION_SEQUENCE = "[SECTION] PUT_SECTION_SEQUENCE";
export const POST_SECTION = "[SECTION] POST_SECTION";

// PLAY LIST
export const GET_PLAYLIST = "[SECTION] GET_PLAYLIST";
export const GET_PLAYLIST_SEARCH = "[SECTION] GET_PLAYLIST_SEARCH";
export const GET_PLAYLIST_ERROR = "[SECTION] GET_PLAYLIST_ERROR";
export const POST_PLAYLIST_LOAD = "[SECTION] POST_PLAYLIST_LOAD";
export const POST_SECTION_PLAYLIST = "[SECTION] POST_SECTION_PLAYLIST";
export const GET_SECTION_PLAYLIST_BY_ID =
  "[SECTION] GET_SECTION_PLAYLIST_BY_ID";
export const GET_SECTION_PLAYLIST_ALL = "[SECTION] GET_SECTION_PLAYLIST_ALL";
export const DELETE_SECTION_LIST = "[SECTION] DELETE_SECTION_LIST";

export const PATCH_EDIT_PLAY_LIST = "[SECTION] PATCH_EDIT_PLAY_LIST";
export const PATCH_EDIT_PLAY_LIST_ERROR =
  "[SECTION] PATCH_EDIT_PLAY_LIST_ERROR ";
export const DELETE_PLAYLIST_LIST = "[SECTION] DELETE_PLAYLIST_LIST";
export const COLLASPE_PLAYLIST_LIST = "[SECTION] COLLASPE_PLAYLIST_LIST";
export const TOGGLE_PLAY_FEATURE = "[SECTION] TOGGLE_PLAY_FEATURE";

export const LOOP_TRACK = "[SECTION] LOOP_TRACK";
export const BUTTON_STATE_TRACK = "[SECTION] BUTTON_STATE_TRACK";
export const BUTTON_ICON_TRACK = "[SECTION] BUTTON_ICON_TRACK";
export const QUICK_FADE_IN_OUT = "[SECTION] QUICK_FADE_IN_OUT"; //QUICK_FADE_IN_OUT
export const VOLUME_TRACK = "[SECTION] VOLUME_TRACK";
export const PINNING_PLAYLIST_LIST = "[SECTION] PINNING_PLAYLIST_LIST";

export const GET_TRACKLIST = "[SECTION] GET_TRACKLIST";

export const POST_TRACKS_UPLOAD = "[SECTION] POST_TRACKS_UPLOAD";

//TRACKLIST_CONTROLLER
export const GET_TRACKLIST_CONTROLLER = "[SECTION] GET_TRACKLIST_CONTROLLER";

export const POST_TRACKTOPLAYLIST = "[SECTION] POST_TRACKTOPLAYLIST";
export const POST_TRACKTOPLAYLIST_ERROR =
  "[SECTION] POST_TRACKTOPLAYLIST_ERROR";
export const PATCH_TRACK_SHUFFLE = "[SECTION] PATCH_TRACK_SHUFFLE";
export const DELETE_PLAYLIST_LIST_BY_ID =
  "[SECTION] DELETE_PLAYLIST_LIST_BY_ID";

export const POST_ACCOUNT_SETTING = "[SECTION] POST_ACCOUNT_SETTING";
export const POST_CHANGE_PASSWORD = "[SECTION] POST_CHANGE_PASSWORD";

//GET_PLAYLIST_SIDEBAR FLAG
export const GET_PLAYLIST_SIDEBAR = "[SECTION] GET PLAYLIST SIDEBAR";

export const SET_PLAYLIST_TRACK_PLACEHOLDER =
  "[SECTION] SET PLAYLIST TRACK PLACEHOLDER";
export const UNSET_PLAYLIST_TRACK_PLACEHOLDER =
  "[SECTION] UNSET PLAYLIST TRACK PLACEHOLDER";

export const DELETE_PLAYLIST_TRACK_BY_ID_PENDING =
  "DELETE_PLAYLIST_TRACK_BY_ID_PENDING";
export const DELETE_PLAYLIST_TRACK_BY_ID_FULFILLED =
  "DELETE_PLAYLIST_TRACK_BY_ID_FULFILLED";
export const DELETE_PLAYLIST_TRACK_BY_ID_REJECTED =
  "DELETE_PLAYLIST_TRACK_BY_ID_REJECTED";

// Updates a playlist track with a new playlist
export const UPDATE_PLAYLIST_TRACK = "UPDATE_PLAYLIST_TRACK"; //

export const GET_AVAILABLE_STORAGE = "GET_AVAILABLE_STORAGE";

export const DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM =
  "DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM";

export function handleSectionList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      getSectionList(values)
        .then((response) => {
          dispatch({
            type: GET_SECTION,
            payload: response,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleEditSectionName(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await editSectionName(values);
      dispatch({
        type: EDIT_SECTION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSectionSequence(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await getSectionSequance(values);
      dispatch({
        type: PUT_SECTION_SEQUENCE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSectionPost(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await postSection(values);
      dispatch({
        type: POST_SECTION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAddPlaylist(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await postSectionPlaylist(values);
      dispatch({
        type: POST_SECTION_PLAYLIST,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handlePlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_PLAYLIST_LOAD,
        payload: true,
      });
      getPlayList(values)
        .then((response) => {
          dispatch({
            type: GET_PLAYLIST,
            payload: response,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      dispatch({
        type: GET_PLAYLIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSectionPlaylistByID(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await getSectionPlaylistByID(values);
      dispatch({
        type: GET_SECTION_PLAYLIST_BY_ID,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSectionPlaylistAll(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });

      getSectionPlaylistAll(values)
        .then((response) => {
          dispatch({
            type: GET_SECTION_PLAYLIST_ALL,
            payload: response,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleDeleteSectionList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await deleteSectionList(values);
      dispatch({
        type: DELETE_SECTION_LIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleEditPlaylistName(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await editPlaylistName(values);
      dispatch({
        type: PATCH_EDIT_PLAY_LIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleDeletePlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await deletePlaylist(values);
      dispatch({
        type: DELETE_PLAYLIST_LIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

// rewrite of `handleDeletePlayListTrack` with correct action/reducer usage.
export function handleDeletePlayListTrackById(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PLAYLIST_TRACK_BY_ID_PENDING,
        payload: { id },
      });
      const response = await deletePlaylistTrack(id);
      dispatch({
        type: DELETE_PLAYLIST_TRACK_BY_ID_FULFILLED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: DELETE_PLAYLIST_TRACK_BY_ID_REJECTED,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleDeletePlayListTrack(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await deletePlaylistTrack(values);
      dispatch({
        type: DELETE_PLAYLIST_LIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleCollaspePlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await collaspePlaylist(values);

      dispatch({
        type: COLLASPE_PLAYLIST_LIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTogglePlayPlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });

      const response = await togglePlayPlayList(values);

      dispatch({
        type: TOGGLE_PLAY_FEATURE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handlePinningPlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await pinningPlaylist(values);
      dispatch({
        type: PINNING_PLAYLIST_LIST,
        payload: response,
      });
      return response
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await trackList(values);
      dispatch({
        type: GET_TRACKLIST,
        payload: response,
      });
      dispatch({
        type: GET_PLAYLIST_SIDEBAR,
        payload: true,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackController(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      trackTracksController(values)
        .then((response) => {
          dispatch({
            type: GET_TRACKLIST_CONTROLLER,
            payload: response,
          });
          dispatch({
            type: GET_PLAYLIST_SIDEBAR,
            payload: true,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleLoopTrack(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await loopTrack(values);
      dispatch({
        type: LOOP_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
export function handleButtonStateTrack(values, is_lock, lock_upload, pinned) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: false,
      });
      const response = await buttonStateTrack(values);
      response.is_lock = is_lock;
      response.lock_upload = lock_upload;
      response.pinned_playlist = pinned
      dispatch({
        type: BUTTON_STATE_TRACK,
        payload: response,
      });
      dispatch({
        type: UPDATE_PLAYLIST_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleButtonIconTrack(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await buttonIconTrack(values);
      dispatch({
        type: BUTTON_ICON_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/*Fade in and out handler */
export function handleFadeInOutTrack(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await fadeInOutTrack(values);
      dispatch({
        type: QUICK_FADE_IN_OUT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleVolumeTrack(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = volumeControlTrack(values);
      dispatch({
        type: VOLUME_TRACK,
        payload: response,
      });
      dispatch({
        type: UPDATE_PLAYLIST_TRACK,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSearchTrackList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await searchTrackList(values);
      // dispatch({
      //   type: GET_TRACKLIST,
      //   payload: response,
      // });
      return response;
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSearchPlayList(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await getSearchPlayList(values);
      if (values) {
        dispatch({
          type: GET_PLAYLIST_SEARCH,
          payload: response,
        });
        return response;
        /*  dispatch({
          type: GET_PLAYLIST,
          payload: response,
        }); */
      } else {
        dispatch({
          type: GET_PLAYLIST,
          payload: response,
        });
        dispatch({
          type: GET_PLAYLIST_SIDEBAR,
          payload: true,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PLAYLIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackToPlaylist(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      let finalValue = {
        button_state: values?.button_state,
        play_order: values?.play_order,
        playlist_id: values?.playlist_id,
        track_id: values?.track_id,
        volume: values?.volume,
      };
      const response = await trackToPlaylist(finalValue);
      response.is_lock = values?.is_lock;
      response.lock_upload = values?.lock_upload;
      dispatch({
        type: POST_TRACKTOPLAYLIST,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_TRACKTOPLAYLIST_ERROR,
        payload: { errorMessage: error.message },
      });
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTrackShuffle(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await trackShuffle(values);
      dispatch({
        type: PATCH_TRACK_SHUFFLE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PATCH_EDIT_PLAY_LIST_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleExistingSectionToPlay(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await addExistingToPlaylist(values);
      dispatch({
        type: POST_SECTION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
/* Delete Playlist by ID Sidebar */
export function handleDeletePlayListById(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await deletePlaylistByID(values);
      dispatch({
        type: DELETE_PLAYLIST_LIST_BY_ID,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Account Setting */
export function handleAccount(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await accountSetting(values);
      dispatch({
        type: POST_ACCOUNT_SETTING,
        payload: response,
      });
      return response
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

/* Account Setting */
export function handleChangePass(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await changePassword(values);
      dispatch({
        type: POST_CHANGE_PASSWORD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

//tracksUpload
export function handleTracksUpload(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await tracksUpload(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleRemoveUploadedFile(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await removeUploadedFileFromS3(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
//remove tracks from file upload
export function handleRemoveAllUploadedFile(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await removeAllUploadedFileFromS3(values);
      dispatch({
        type: POST_TRACKS_UPLOAD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAvailableStorage() {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await availableS3Storage();
      dispatch({
        type: GET_AVAILABLE_STORAGE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleTracksUploadDelete(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_SECTION_LOAD,
        payload: true,
      });
      const response = await deleteUploadTracks(id);
      dispatch({
        type: DELETE_TRACKS_LIBRARY_UPLOAD_ALBUM,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_SECTION_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}
