import { axiosInstance } from "common/axiosConfig";
import { showToast } from "common/function/function";
import { errorLog } from "common/utility/utils";
/* Get All section List  */
export const getSectionList = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`sections`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorLog(error);
        // error.response && error.response.data.message
        //   ? showToast("Failed !", error.response.data.message, "error")
        //   : reject({ message: `Something Went Wrong` });
      })
  );

/*Edit Section Name */
export const editSectionName = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`sections/${value?.id}`, {
        section: value?.name,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Get All section List update sequance*/
export const getSectionSequance = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .put(`sections`, value)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Add section  */
export const postSection = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`sections`, value)
      .then((response) => {
        resolve(response && response?.data && response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Get All Playlist */

export const getPlayList = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`playlists`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorLog(error);
      })
  );

/* Get All Playlist */

export const getSearchPlayList = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`playlists?search=${value}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorLog(error);
      })
  );

/* Section PlayList */
export const postSectionPlaylist = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`section-playlist`, value)
      .then((response) => {
        resolve(response.data.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Get Section playlist by id's */
export const getSectionPlaylistByID = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`sections/${value}`)
      .then((response) => {
        resolve(response.data.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        /* error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` }); */
      })
  );

/* Get all section playlist */
export const getSectionPlaylistAll = (token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`sections?detailed=true`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
    /*     error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` }); */
      })
  );

/* Delete Section list */
export const deleteSectionList = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`sections/${value}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Update Playlist name */
export const editPlaylistName = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlists/${value?.id}`, { name: value?.name })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Delete Playlist */
export const deletePlaylist = (id, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`section-playlist/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Delete Playlist Track*/
export const deletePlaylistTrack = (id, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`playlist-track/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* collapse Playlist  */
export const collaspePlaylist = (values, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`section-playlist/${values?.id}`, { collapsed: values?.collapse })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Toggle Play Playlist  */
export const togglePlayPlayList = (values) =>
new Promise((resolve, reject) =>
  axiosInstance
    .patch(`section-playlist/${values?.id}`, { toggled: values?.toggled })
    .then((response) => {
      resolve(response?.data?.data);
      // showToast("Successfully !", response?.data?.message, "success");
    })
    .catch((error) => {
      errorLog(error);
      error.response && error.response.data.message
        ? showToast("Failed !", error.response.data.message, "error")
        : reject({ message: `Something Went Wrong` });
    })
);

/* Pinning Playlist */
export const pinningPlaylist = (values, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlists/pinplaylist/${values?.id}`, {
        pinned: values?.pinned,
        pinned_exposed_rows: values?.pinned_exposed_rows,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Get Track list */
export const trackList = (token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        // error.response && error.response.data.message
        //   ? showToast("Failed !", error.response.data.message, "error")
        //   : reject({ message: `Something Went Wrong` });
      })
  );

//TracksController_findNestedAll
export const trackTracksController = (token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/nested-tracks`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        // error.response && error.response.data.message
        //   ? showToast("Failed !", error.response.data.message, "error")
        //   : reject({ message: `Something Went Wrong` });
      })
  );

export const searchTrackList = (value) =>
  new Promise((resolve, reject) => {
    const defaultRecords = value?.perPage;
    const params = {
      page: value?.page,
      perPage: defaultRecords,
    };
    if (value) {
      if (value.search) params.search = value.search;
      if (value.page) params.page = value.page;
      if (value.perPage) params.perPage = value.perPage;
    }
    axiosInstance
      .get("/tracks", { params: { ...params } })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      });
  });

/* Add track to playlist */
export const trackToPlaylist = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`playlist-track`, value)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        const message =
          (error.response && error.response.data.message) ||
          `Something Went Wrong`;
        if (message) showToast("Failed !", message, "error");
        reject({ message });
      })
  );

/* Suffle wavesurfer */
export const trackShuffle = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`playlist-track/update-play-order`, value)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Add existing playlist to section */
export const addExistingToPlaylist = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`section-playlist/add-existing-playlist`, value)
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Loop Track */
export const loopTrack = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlist-track/${value?.id}`, {
        update_case: "loop",
        loop: value?.loop,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/*   Button Icon */

export const buttonIconTrack = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlist-track/${value?.id}`, {
        update_case: "button_icon",
        button_icon: value?.button_icon,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Fade in and out*/
export const fadeInOutTrack = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlist-track/${value?.id}`, {
        update_case: "quick_fade",
        quick_fade: value?.quick_fade,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Button State Track */
export const buttonStateTrack = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlist-track/${value?.id}`, {
        update_case: "button_state",
        button_state: value?.button_state,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Volume Track */
export const volumeControlTrack = (value, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .patch(`playlist-track/${value?.id}`, {
        update_case: "volume",
        volume: value?.volume,
      })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* Delete Playlist By ID in SideBar*/
export const deletePlaylistByID = (id, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`playlists/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/*Account setting */
export const accountSetting = (values, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`users/update-profile`, {
        fullname: values?.fullname,
        password: values?.password,
      })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/*Account setting */
export const changePassword = (values, token) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`users/change-password`, {
        password: values?.password,
        new_password: values?.new_password,
        confirm_password: values?.confirm_password,
      })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

/* tracks upload */
export const tracksUpload = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`tracks/uploads`, { tracks: values })
      .then((response) => {
        resolve(response?.data?.data);
        showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

//tracks/generate/presigned-post-url-S3
export const getS3Post = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/generate/presigned-post-url-S3`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );

//playlists/pinplaylist/${values?.id}
export const removeUploadedFileFromS3 = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`tracks/remove/file-from-S3`, { tracks: values })
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );

export const removeAllUploadedFileFromS3 = (values) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/remove-file-from-S3/${values}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );

export const availableS3Storage = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/storage/info`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );

export const deleteUploadTracks = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .delete(`tracks/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
        // showToast("Successfully !", response?.data?.message, "success");
      })
      .catch((error) => {
        errorLog(error);
        error.response && error.response.data.message
          ? showToast("Failed !", error.response.data.message, "error")
          : reject({ message: `Something Went Wrong` });
      })
  );
