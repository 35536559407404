import { decrypt, encrypt } from "./storage";
import { APP } from "../constant";

export const createCookie = (name, value, day = APP.COOKIE_DAYS) => {
  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + day);
  expiryDate = expiryDate.toISOString();

  const maxAge = day * 24 * 60 * 60;
  const encryptedValue = encrypt(value);
  document.cookie = `${name}=${encryptedValue}; path=/; expires=${expiryDate}; max-age=${maxAge}`;
};
export const getCookie = (name) => {
  let nameEq = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(nameEq) === 0) {
      let encryptedValue = c.substring(nameEq.length, c.length);
      let decryptedValue = decrypt(encryptedValue);
      return decryptedValue;
    }
  }
  return "";
};

export const eraseCookie = (name) => {
  createCookie(name, "", -1);
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const deleteAllCookies = () => {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name === "searchResult") {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
};
