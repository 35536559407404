// immutable helper function that deletes a track from a section playlist
export const deleteTrackFromSectionPlaylists = (state, predicate) => {
  // the predicate is the function which determines which tracks to delete and which to keep

  const newPlaylists = new Map();
  const { playList, sectionPlayListAll } = state;

  // loop through all playlists to build a initial mapping of playlists
  for (const pl of playList?.data || []) {
    newPlaylists.set(pl.id, pl);
  }

  const newSectionPlayListAll = sectionPlayListAll.map((section) => {
    let didUpdatePlaylist = false;
    const newSectionPlaylists = section.playlists.map((sectionPlaylist) => {
      const hasTrack = sectionPlaylist.tracks.some((track) => predicate(track));

      // if this playlist has no tracks, ignore it and return the old reference
      if (hasTrack) {
        didUpdatePlaylist = true;
        const newTracks = sectionPlaylist.tracks.filter(
          (track) => !predicate(track)
        );

        // create the new playlist which will be stored in the playlist mapping
        const playlistAttributes = { ...sectionPlaylist };
        delete playlistAttributes.collapsed;
        delete playlistAttributes.playlist_id;
        delete playlistAttributes.id;
        delete playlistAttributes.section_id;
        const newPlaylist = {
          ...playlistAttributes,
          id: sectionPlaylist.playlist_id,
          tracks: newTracks,
        };
        newPlaylists.set(newPlaylist.id, newPlaylist);

        // create the section playlist
        return {
          ...sectionPlaylist,
          tracks: newTracks,
        };
      } else {
        return sectionPlaylist;
      }
    });
    // we only return a new reference if things have actually updated
    if (didUpdatePlaylist)
      return {
        ...section,
        playlists: newSectionPlaylists,
      };
    else return section; // return old one if nothing has changed
  });

  const newPlaylistData = Array.from(newPlaylists.values());

  return {
    sectionPlayListAll: newSectionPlayListAll,
    playList: {
      ...playList,
      data: newPlaylistData,
    },
  };
};
