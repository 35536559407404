import { REACT_APP_WEBSITE_URL } from "config";
import { removeLocal } from "common/function/storage";
import {
  postLogin,
  replacePostLogin,
  getAuthVerify,
  getAuthRegisterVerify,
  postSignUp,
  postFacebookLogin,
  postGoogleLogin,
  postResetPassword,
  getForgotPassword,
  getSendMail,
  getLogout,
  multipleInviteUser
} from "common/services/auth/services";

export const POST_AUTH_LOAD = "[LOGIN] POST_AUTH_LOAD";
export const POST_LOGIN = "[LOGIN] POST_LOGIN";
export const POST_AUTH_ERROR = "[LOGIN] POST_AUTH_ERROR";
export const GET_AUTH_VERITY = "[AUTH] GET_AUTH_VERITY";
export const POST_SIGNUP = "[SIGN_UP] POST_SIGNUP";
export const POST_FACEBOOK_LOGIN = "[FACEBOOK] POST_FACEBOOK_LOGIN";
export const POST_GOOGLE_LOGIN = "[GOOGLE] POST_GOOGLE_LOGIN";
export const POST_RESET_PASSWORD = "[GOOGLE] POST_RESET_PASSWORD";
export const GET_FORGOT_PASSWORD = "[GOOGLE] GET_FORGOT_PASSWORD";
export const GET_SEND_MAIL = "[SEND] GET_SEND_MAIL";
export const GET_LOGOUT = "[LOGOUT] GET_LOGOUT";
export const POST_ACCOUNT_SETTING = "[SECTION] POST_ACCOUNT_SETTING";
export const GET_SECTION_PLAYLIST_ALL = "[SECTION] GET_SECTION_PLAYLIST_ALL";
export const GET_SECTION = "[SECTION] GET_SECTION";
let redirectWebsite = REACT_APP_WEBSITE_URL ? REACT_APP_WEBSITE_URL + '/auth/signin' : "https://development.store.battlebards.dev"
export function handleLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postLogin(values);
      dispatch({
        type: POST_LOGIN,
        payload: response,
      });
      if (!response?.already_logged_in) history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleReplaceLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await replacePostLogin(values);
      dispatch({
        type: POST_LOGIN,
        payload: response,
      });
      history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAuthVerify(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getAuthVerify(values);
      dispatch({
        type: GET_AUTH_VERITY,
        payload: response,
      });
      history.push("/sign-in");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleAuthRegisterVerify(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getAuthRegisterVerify(values);
      dispatch({
        type: POST_LOGIN,
        payload: response?.data,
      });
      history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSignUp(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postSignUp(values);
      dispatch({
        type: POST_SIGNUP,
        payload: response,
      });
      history.push("/sign-in");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function facebookLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postFacebookLogin(values);
      dispatch({
        type: POST_FACEBOOK_LOGIN,
        payload: response,
      });
      if (!response?.already_logged_in) history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function googleLogin(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postGoogleLogin(values);
      dispatch({
        type: POST_GOOGLE_LOGIN,
        payload: response,
      });
      if (!response?.already_logged_in) history.push("/");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleResetPassword(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await postResetPassword(values);
      dispatch({
        type: POST_RESET_PASSWORD,
        payload: response,
      });
      history.push("/sign-in");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleForgotPassword(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getForgotPassword(values);
      dispatch({
        type: GET_FORGOT_PASSWORD,
        payload: response,
      });
      history.push("/sign-in");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleSendMail(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getSendMail(values);
      dispatch({
        type: GET_SEND_MAIL,
        payload: response,
      });
      history.push("/sign-in");
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleLogout(history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      const response = await getLogout();

      dispatch({
        type: POST_LOGIN,
        payload: {},
      });
      dispatch({
        type: POST_FACEBOOK_LOGIN,
        payload: {},
      });
      dispatch({
        type: POST_GOOGLE_LOGIN,
        payload: {},
      });
      dispatch({
        type: POST_ACCOUNT_SETTING,
        payload: {},
      });

      dispatch({ type: POST_LOGIN, payload: null });
      dispatch({ type: GET_SECTION_PLAYLIST_ALL, payload: [] });
      dispatch({ type: GET_SECTION, payload: [] });
      dispatch({ type: "PLAY_TRACK_ID", payload: "" });
      //  dispatch({ type: "SIDEBAR", payload: "playlist" });
      dispatch({ type: "MAIN_SIDEBAR", payload: false });
      dispatch({ type: "CREATE_PLAYLIST", payload: false });
      dispatch({ type: "SIDEBAR_VOLUME", payload: 50 });
      dispatch({ type: "PLAY_ICON", payload: [] });
      dispatch({ type: "PLAY_ICON", payload: [] });
      dispatch({ type: "STOP_ALL_PLAYING_TRACKS", payload: null });
      dispatch({
        type: GET_LOGOUT,
        payload: response,
      });
      /* history.push("/sign-in"); */
      window.open(redirectWebsite, "_self");
      await Promise.all([
        removeLocal("access_token"),
        removeLocal("refresh_token"),
        removeLocal("persist:root"),
        localStorage.clear(),
      ]);
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}

export function handleInviteUser(values, history) {
  return async (dispatch) => {
    try {
      dispatch({
        type: POST_AUTH_LOAD,
        payload: true,
      });
      let emails = {emails:values}
      const response = await multipleInviteUser(emails);
      return response
    } catch (error) {
      dispatch({
        type: POST_AUTH_ERROR,
        payload: { errorMessage: error.message },
      });
    }
  };
}