import { axiosInstance } from "common/axiosConfig";
import { errorLog } from "common/utility/utils";
/* Get All section List  */
export const getPlayTimeUrl = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`tracks/${value}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );

  /*Generate Peaks */
export const generatePeekAPI = (value) =>
new Promise((resolve, reject) =>
  axiosInstance
    .post(`tracks/peaks-duration-update`, value)
    .then((response) => {
      resolve(response.data.data);
    })
    .catch((error) => {
      errorLog(error);
      reject({ message: `Something Went Wrong` });
    })
);

//SQS Event fire
export const PostSQS = (value) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/tracks/sqs/event-store`, value)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        errorLog(error);
        reject({ message: `Something Went Wrong` });
      })
  );